//vuex   还有k12出题
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    toggle_collapsed: true,
    ISmqtt: true,
    //再来一个控制小红点的
    IsBadge: false,
    manage_classId: null, //学情的班级id,
    TimeId: null,
    grade: {
      value: 1,
      label: "一年级"
    }, //年级
    subject: {
      value: 1,
      label: "数学"
    }, //学科
    chapter: {
      id: "",
      name: ""
    }, //章节
    chapterData: [],
    page: 1,
    tableData: [],
    total: 0, //总页数
    Variantid: '', //变式id
    BrowserRouter: false, //浏览器拦截路由开关
    screenval: [], //广州学生进入筛选条件
  },
  getters: {
    toggle_collapsed: state => state.toggle_collapsed,
  },
  mutations: {
    setGrade: (state, grade) => {
      state.grade = grade
    },
    setScreenval: (state, grade) => {
      state.screenval = grade
    },
    setBrowserRouter: (state, BrowserRouter) => {
      state.BrowserRouter = BrowserRouter
    },
    setVariantid: (state, Variantid) => {
      state.Variantid = Variantid
    },
    setTableData: (state, tableData) => {
      state.tableData = tableData
    },
    setPage: (state, page) => {
      state.page = page
    },
    setTotal: (state, total) => {
      state.total = total
    },
    setPage: (state, page) => {
      state.page = page
    },
    setTotal: (state, total) => {
      state.total = total
    },
    setSubject: (state, subject) => {
      state.subject = subject
    },
    setChapter: (state, chapter) => {
      state.chapter = chapter
    },
    setChapterData: (state, chapterData) => {
      state.chapterData = chapterData
    },

    setCollapse: (state, toggle_collapsed) => {
      state.toggle_collapsed = toggle_collapsed
    },
    setISmqtt: (state) => {
      state.ISmqtt = false
    },
    setIsBadge: (state) => {
      state.IsBadge = true
    },
    setIsBadge1: (state) => {
      state.IsBadge = false
    },
    // 保存学情页的classid
    setClassId: (state, classId) => {
      state.manage_classId = classId
    },
    // 重置classId
    replacement_classId: (state) => {
      state.manage_classId = null
    },
    // 保存定时器id
    timeRefresh: (state, id) => {
      state.TimeId = id
    }
  },
  actions: {},
  modules: {}
})