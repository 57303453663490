export default {
  s_to_ms: function (s) { //必须传秒数
    //计算分钟
    //算法：将秒数除以60，然后下舍入，既得到分钟数
    var m;
    m = Math.floor(s / 60);
    //计算秒
    //算法：取得秒%60的余数，既得到秒数
    s = s % 60;
    //将变量转换为字符串
    m += '';
    s += '';
    //如果只有一位数，前面增加一个0
    m = (m.length == 1) ? '0' + m : m;
    s = (s.length == 1) ? '0' + s : s;
    return m + ':' + s;
  },
  ms_to_s: function (ms) { //传时间格式必须为  00：00
    let time = ms.split('：')
    if (time.length == 1) {
      time = ms.split(':')
    }
    let second = 0
    time.map((item, index) => {
      item = Number(item)
      if (index == 0) {
        second = second + item * 60
      }
      if (index == 1) {
        second = second + item
      }
    })
    return second
  },
  getDateStr:function(seconds){//秒转换成标准时间，带年月日
    var date = new Date(seconds*1000)
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
        var minute = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
        var second = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
        var currentTime = year + "-" + month + "-" + day + "  " + hour + ":" + minute + ":" + second;
        return currentTime
},
}