var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-menu',{class:!_vm.collapsed ? 'title-menu' : '',attrs:{"collapse":_vm.collapsed,"unique-opened":true,"default-active":_vm.$route.path,"collapse-transition":false,"background-color":"#fff","text-color":"#367EE7","active-text-color":"#ffd04b"},on:{"select":_vm.change_aside_menu,"open":_vm.handleOpen,"close":_vm.handleClose}},[_vm._l((_vm.menuList),function(item){return [(item.meta.isShow && item.meta.haveChild)?_c('el-submenu',{key:item.name,attrs:{"index":item.path}},[_c('template',{slot:"title"},[_c('i',{class:'icon ' + item.meta.iconClass}),_c('span',{staticClass:"title-span"},[_vm._v(" "+_vm._s(item.meta.title=='班级'?'小组':item.meta.title))])]),_vm._l((item.children),function(jtem,index){return _c('el-menu-item',{key:index,attrs:{"index":jtem.path}},[_vm._v(_vm._s(jtem.meta.title=='班级'?'小组':jtem.meta.title))])})],2):(
        item.meta.isShow &&
        !item.meta.haveChild &&
        item.meta.teacherShow &&
        item.children
      )?_c('el-submenu',{key:item.name,attrs:{"index":item.path}},[_c('template',{slot:"title"},[_c('i',{class:'icon ' + item.meta.iconClass}),_c('span',{staticClass:"title-span"},[_vm._v(_vm._s(item.meta.title=='班级'?'小组':item.meta.title))])]),_vm._l((item.children),function(jtem,index){return (jtem.meta.teacherShow)?_c('el-menu-item',{key:index,staticClass:"item_p",attrs:{"index":jtem.path}},[_vm._v(_vm._s(jtem.meta.title=='班级'?'小组':jtem.meta.title))]):_vm._e()}),_vm._l((item.children),function(jtem,index){return (jtem.meta.doubleDeck)?_c('el-submenu',{key:index,staticClass:"item_p",attrs:{"index":jtem.path}},[_c('template',{slot:"title"},[_c('span',{staticClass:"item_p"},[_vm._v(_vm._s(jtem.meta.title))])]),_vm._l((jtem.children),function(tem,index){return (tem.meta.teacherShow)?_c('el-menu-item',{key:index,staticClass:"item_p",attrs:{"index":tem.path}},[_vm._v(_vm._s(tem.meta.title))]):_vm._e()})],2):_vm._e()})],2):(
        item.meta.isShow && !item.meta.haveChild && item.meta.teacherShow
      )?_c('el-menu-item',{key:item.name,attrs:{"index":item.path}},[_c('i',{class:'icon ' + item.meta.iconClass}),_c('span',{staticClass:"title-span",attrs:{"slot":"title"},slot:"title"},[_vm._v(_vm._s(item.meta.title=='班级'?'小组':item.meta.title))])]):_vm._e()]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }