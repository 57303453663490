// 传入数字算出答案
export const judgeOdevity = (num, arr = [], times = 0) => {
  // let arr = [] // 要输出的结果
  // let times = 0 // 除以2的次数
  let splitNum;
  if (num < 1) {
    return
  }
  // 判断数字奇偶性,直到数字小于1为止
  if (num % 2) { // 奇数
    // 2的指数幂只有1为奇数， 所以这个幂指数可以由1和一个偶数来表示，1不能再拆分，所以应该拿出放入结果集
    // 因为这个1是除以2得到的值，所以这个1应该用2的拆分次数幂表示（2^times）
    arr.push(Math.pow(2, times))
    // 减掉1（num - 1）再除以2，得到新的数字
    splitNum = (num - 1) / 2
  } else { // 偶数
    // 直接除以2（num/2）得到新的数字
    splitNum = num / 2
  }
  // 判断新数字的奇偶性，times加1
  times += 1
  judgeOdevity(splitNum, arr, times)
  return arr
}

// 计算用户选择的
export const letter = (letter_arr, zm_arr) => {
  if (!letter_arr) return []
  // console.log(letter_arr)
  // console.log(zm_arr)
  // 用户的选项
  // letter_arr  用户的选项之和  [1,4,8]
  // zm_arr  所有的选项 [A,B,C]
  zm_arr.sort(function (a, b) {
    return a.localeCompare(b, 'zh')
  })
  let arr = []
  let b = []
  for (var i = 0; i < letter_arr.length; i++) {
    var index = Math.log(letter_arr[i]) / Math.log(2);
    b.push(index);
  }
  // console.log(b)
  // letter_arr.map((item, index) => {
  zm_arr.forEach((zm_item, zm_index) => {
    b.forEach(b_item => {
      if (zm_index == b_item) {
        arr.push(zm_item)
      }
    })
  })
  // })
  return arr
};