<!-- 题目 我是答题页 -->

<template>
  <div
    class="topic-details"
    v-loading="loading"
    element-loading-text="提交答案中"
  >
    <div id="scroll"></div>
    <screen
      :knowledge="knowledge"
      @searchStudent="searchStudent"
      :show="!ProfessorOrNot"
    ></screen>
    <!-- 活动指导 -->
    <span class="activity-guidance">
      <span @click="dialogVisible = true">
        <i class="el-icon-warning"></i>
        <span>活动指导</span>
      </span>
    </span>
    <!-- 计时器 -->
    <span v-if="ISvariant && !ProfessorOrNot" class="timer-text">
      进行该变式活动时长：
      <timer :totalTime="totalTime1"></timer>
    </span>
    <span v-if="!ISvariant && !ProfessorOrNot" class="timer-text">
      查看活动时长：第一次：
      <timer :totalTime="firstTime"></timer>
      <span class="timeBlank"></span>第二次:
      <div class="goInfo">
        <ul>
          <li>{{ TWOtimer.one }}</li>
          <span>:</span>
          <li>{{ TWOtimer.two }}</li>
          <span>:</span>
          <li>{{ TWOtimer.three }}</li>
        </ul>
      </div>
    </span>
    <!-- 题 -->
    <el-card class="active" ref="barparent">
      <el-steps :active="active" finish-status="success">
        <el-step v-for="(item, i) in taskAmount" :key="i"></el-step>
      </el-steps>

      <el-card :class="ProfessorOrNot ? 'box-card-left-a' : 'box-card-left'">
        <!-- 标签 -->
        <el-scrollbar style="height: 100%" v-if="this.ProfessorOrNot">
          <div>
            <span class="caseSN_span">当前问题：{{ caseSN }}</span>
            <span class="caseSN_span" v-if="ProfessorOrNot"
              >标签模板:{{ labelManagement.templateName }}</span
            >
            <el-divider></el-divider>
            <div
              class="Tagquestions"
              v-html="Tagquestions"
              v-if="this.ProfessorOrNot"
              style="height: 100%; width: 889px"
            ></div>
            <el-divider></el-divider>
            <div class="analysis" v-if="ProfessorOrNot" style="display: flex">
              本题解析:
              <i v-html="labelManagement.SubjectAnalytical"></i>
            </div>
            <el-divider></el-divider>
            <span class="caseSN_span caseSN_keyword"
              >关键字：
              <template v-if="keyword.length > 0">
                <el-tag
                  class="caseSN_tag"
                  v-for="(i, index) in keyword"
                  :key="index"
                  >{{ i }}</el-tag
                ></template
              >
              <template v-if="keyword.length <= 0"> 暂无 </template>
            </span>
          </div>
        </el-scrollbar>

        <el-scrollbar style="height: 100%" v-if="!this.ProfessorOrNot">
          <div>
            <span v-if="false">题目描述</span>
            <ul>
              <li v-for="(item, i) in descriptions" :key="i">
                {{ descriptionType[item.descriptionType] }}
                <div :class="item.descriptionType == 6 ? 'des_p' : ''">
                  <i v-html="item.description" class="des"></i>
                </div>
              </li>
            </ul>
          </div>
        </el-scrollbar>
      </el-card>
      <div class="resize"></div>
      <el-card
        :class="ProfessorOrNot ? 'box-card-right-b' : 'box-card-right'"
        ref="box"
      >
        <el-switch
          style="display: block"
          v-model="switchValue"
          active-color="#13ce66"
          inactive-color="#ff4949"
          active-text="全部"
          inactive-text="错题"
          v-if="showSwitch"
          @change="switchChange"
        ></el-switch>
        <!-- 我是标签 woshibiaoqian -->
        <el-scrollbar style="height: 100%" ref="elscrollbar">
          <span v-if="questions.length == 0 && !this.ProfessorOrNot"
            >您的答案全对！</span
          >
          <div id="mores"></div>
          <!-- //标签........ -->
          <!-- //0数值型，1多选型，2单选型，3文本型 -->
          <div v-if="this.ProfessorOrNot">
            <p
              class="tagselect"
              v-if="completionStatus && this.ProfessorOrNot == '提交答案'"
            >
              <el-select
                v-model="labelManagement.standardAnswers"
                placeholder="参考答案"
                size="small"
                @change="ChangeReferenceVersion"
              >
                <el-option
                  v-for="item in referenceVersion"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </p>

            <TagQuestions
              :num="resourceId"
              :data="tagTemplateData"
              :ProfessorOrNot="ProfessorOrNot"
              :completionStatus="completionStatus"
              :openId="openId"
              id="label"
              @change-label-data="changeLableData"
              @chang-Expert-Analysis="changExpertAnalysis"
            />
          </div>

          <!-- //下面是正常的 -->
          <div v-if="!this.ProfessorOrNot">
            <div v-for="(item, i) in questions" :key="i" class="topic">
              <span class="span_sn">
                {{ item.sn }}
                <i class="qtype">[ {{ questionsType[item.qtype] }} ]</i>
              </span>
              <p v-html="item.stemContent" class="stemContent"></p>
              <div class="choices">
                <span
                  :class="
                    status == 0 && v.isAnswer == 1 && !viewStudents
                      ? 'text-option class3'
                      : completionStatus || showGoOn
                      ? v.state !== null
                        ? 'text-option class3'
                        : 'text-option'
                      : (answer[item.id] == null
                          ? -1
                          : answer[item.id].indexOf(v.id)) > -1
                      ? 'text-option class3'
                      : 'text-option'
                  "
                  v-for="(v, index) in item.choices"
                  @click="task(item.id, v.id, item.qtype)"
                  :key="index"
                  style="width: 100%; display: block"
                >
                  <span class="sn_span">{{ v.sn }}</span>
                  <i v-html="v.choiceContent" class="choices choices_span"></i>
                  <i
                    style="
                      display: block;
                      color: red;
                      border-top: 1px solid #c5c7cc;
                      margin-top: 10px;
                      padding-top: 10px;
                    "
                    v-if="v.cause && v.state"
                  >
                    错误原因：
                    <i class="tip_i" v-html="v.cause"></i>
                  </i>
                </span>
                <span
                  :class="
                    item.userAnswerResult == 0 ? 'answer green' : 'answer red'
                  "
                  v-if="
                    status == 1 && helpUser !== '帮扶'
                      ? completionStatus || showGoOn
                      : ''
                  "
                >
                  {{ userAnswerResultType[item.userAnswerResult] }}
                  <i class="answerChoices"
                    >正确答案:{{
                      item.answer.toString().replace(/,/g, "、")
                    }}</i
                  >
                  <i style="margin: 0 40px"
                    >第一次选择：{{ item.firstChoice }}</i
                  >
                  <i v-if="item.secondChoice !== ''"
                    >第二次选择：{{ item.secondChoice }}</i
                  >
                </span>
                <span
                  :class="
                    item.userAnswerResult == 0 ? 'answer green' : 'answer red'
                  "
                  v-if="
                    status == 0 && viewStudents
                      ? completionStatus || showGoOn
                      : ''
                  "
                >
                  <i v-if="item.firstChoice">
                    {{ teacherUserAnswerResultType[item.userAnswerResult] }}
                  </i>
                  <i v-if="!item.firstChoice">
                    {{ teacherUserAnswerResultType[2] }}
                  </i>
                  <i class="answerChoices"
                    >正确答案:{{
                      item.answer.toString().replace(/,/g, "、")
                    }}</i
                  >
                  <i v-if="item.firstChoice" style="margin: 0 40px"
                    >第一次选择：{{ item.firstChoice }}</i
                  >
                  <i v-if="item.secondChoice !== ''"
                    >第二次选择：{{ item.secondChoice }}</i
                  >
                </span>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </el-card>
    </el-card>
    <div
      :class="
        this.$store.state.toggle_collapsed
          ? 'activity-button wth'
          : 'activity-button w100'
      "
    >
      <el-button
        :disabled="showButton"
        @click="submit"
        type="primary"
        v-if="!completionStatus && !showGoOn"
        style="padding-right: 14px"
        :loading="!allow_go"
        >{{ allow_go ? "提交" : "提交中" }}</el-button
      >
      <el-button
        type="primary"
        :disabled="showNextQuestion"
        v-if="showGoOn"
        style="padding-right: 14px"
        @click="nextQuestion"
        >继续答题</el-button
      >
      <el-button
        :disabled="showButton1"
        v-if="completionStatus"
        @click="nextSubject"
        type="primary"
        style="padding-right: 14px"
        >下一题</el-button
      >
      <el-button @click="signOut">退出</el-button>
    </div>
    <!-- 活动指导提示框 -->
    <el-dialog stitle="提示" :visible.sync="dialogVisible" width="30%">
      <span style="white-space: pre-wrap">{{ knowledge.teachingMessage }}</span>
      <span slot="footer" class="dialog-footer"></span>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="showDialog"
      width="30%"
      class="warning"
      :show-close="false"
      :before-close="handleClose"
    >
      <span>您有更紧急的任务等您处理！</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="jump">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 查看完成情况 -->
    <el-dialog
      title="提示"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
      :before-close="handleClose"
      :show-close="false"
    >
      <span class="questionsMsgSpan">您的答案{{ questionsMsg }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="viewAnswers">查看答案</el-button>
        <el-button type="primary" @click="nextQuestion" v-if="showNext"
          >下 一 题</el-button
        >
        <el-button type="primary" @click="signOut" v-if="!showNext"
          >退 出</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import screen from "@/components/screen";
import timer from "@/components/timer_s";
import TagQuestions from "../../components/TagQuestions/index.vue";
import { drag } from "../../util/drag.js";
import {
  activeDetails,
  tagactivitysubmit,
  tagActivityList,
  templateDetail,
  resourceDetail,
  tagActivitySpecialist,
  tagExamineAnalysis,
} from "../../api/api";

import { Message, Loading } from "element-ui";

export default {
  data() {
    return {
      //左侧标签内容
      resourceId: null,
      Tagquestions: "",
      radio: "数学",
      TWOtimer: {
        one: "00",
        two: "00",
        three: "06",
      },
      Istag: false,
      //标签模板数据  //  1数值，2单选型，3多选 4文本  typeof判断数据类型 1 2 3 4
      tagTemplateData: [],
      requestTagTemplateData: [],
      //标签答案
      tagTemplateDataanswer: [],
      NoAnswerNum: 0,
      showNextQuestion: false,
      ISvariant: true,
      totalTime1: 0, //总时间
      IStotalTime1: false, //判断是否停止计时器
      firstTime: 0, //第一次答题时间
      secondTime: 0, //第二次答题时间
      ISweiyi: true,
      switchValue: false,
      active: 0,
      noActive: 0,
      taskAmount: null,
      count: 0,
      time: "00:00",
      descriptions: [],
      questions: [],
      selected: false,
      selecteObj: {},
      answer: {},
      dialogVisible: false,
      knowledge: {},
      totalTime: 0,
      descriptionType: this.parameter.descriptionType(),
      questionsType: {
        2: "单选题",
        3: "多选题",
      },
      userAnswerResultType: {
        0: "您的答案正确!",
        1: "您的答案错误!",
      },
      teacherUserAnswerResultType: {
        0: "该学生的答案正确!",
        1: "该学生的答案错误!",
        2: "该学生未作答!",
      },
      showButton: true,
      showButton1: false,
      showNext: true,
      showSwitch: false,
      subjectId: 0,
      completionStatus: false,
      showGoOn: false,
      isComplete: 1,
      helpUser: null,
      startTime: "",
      showDialog: false,
      urgentId: null,
      caseSN: null,
      studentId: null,
      viewStudents: false,
      throttleTimer: true,
      noArr: [],
      appIdUser: null,
      backupsQuestions: [],
      loading: false,
      centerDialogVisible: false,
      questionsMsg: "",
      ProfessorOrNot: null, //判断是不是教授和专家
      labelManagement: {
        templateId: "", //标签模板ID
        stems: [], //题目合集
        classId: "", //班级id
        Id: "", //活动id
        templateName: "", //标签模板名称
        SubjectAnalytical: "", //本题解析
        standardAnswer: null, //参考答案专家id传过来的
        standardAnswers: null, //参考答案专家id选择框
      },
      caseId: null, //当前变式的caseId
      allow_go: true, //点击提交答案之后是否允许点击
      status: 0, //0  老师  1  学生
      caseType: null, //返回的题目类型
      wrongQuestions: [], // 学生是否答错
      // 参考答案选项
      referenceVersion: [],
      openId: [], //默认展开
      questionAnswer: [], //当前题目的正确答案
      keyword: [],
    };
  },
  components: {
    screen,
    timer,
    TagQuestions,
  },
  mounted() {
    let userId = Number(this.utils.userId());
    _hmt.push(["_trackEvent", "答题", "进入", "进入答题页", userId]);
    this.startTime = new Date().getTime(); //当前时间
    this.load();
    this.getUserSpendTime(); //答题总时长
    drag.dragControllerDiv(
      "resize",
      "box-card-left",
      "box-card-right",
      "active"
    );
    // 3秒保存时间
    let timeId = setInterval(() => {
      this.answerTime();
      this.$store.commit("timeRefresh", timeId);
    }, 3000);
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {},
      // 深度观察监听
      deep: true,
    },
    knowledge(val, old) {
      //判断是不是标签
      if (val.stemType == 2) {
        if (this.ProfessorOrNot) {
          this.labelManagement.standardAnswer = Number(val.standardAnswer);
          this.labelManagement.standardAnswers = Number(val.standardAnswer);
          this.labelManagement.templateId = val.templateId;
          this.labelManagement.stems = val.stems;
          this.labelManagement.classId = val.classId;
          this.labelManagement.Id = val.id;
          let arr = val.stems.filter((item) => {
            //判断完成了几道题
            return item.isComplete == 1;
          });
          let target = val.users.find((item) => {
            return item.userId == this.appIdUser;
          });
          if (arr.length !== val.stems.length && target) {
            //所有未完成的
            this.completionStatus = false;
            this.showButton = true;
            this.active = arr.length; //上面小球
            this.ObtainingLabelTemplate(val.templateId); //获取标签模板
            var param = {};
            for (let i = 0; i < val.stems.length; i++) {
              if (val.stems[i].isComplete == 0) {
                param.activeId = val.stems[i].activeId;
                param.stemName = val.stems[i].stemName;
                param.caseId = val.stems[i].caseId;
                param.taskId = val.stems[i].id;
                break;
              }
            }
            this.caseSN = param.stemName;
            resourceDetail({
              //获取最新的题
              id: param.caseId,
            }).then((res) => {
              if (res.data.keyword == "NULL") {
                this.keyword = [];
              } else {
                this.keyword = res.data.keyword.split(",");
              }

              this.labelManagement.SubjectAnalytical = res.data.analysis;
              this.Tagquestions = res.data.topic;
            });
          } else {
            //全部完成了查看答案
            this.completionStatus = true;
            this.ObtainingLabelTemplate(val.templateId); //获取标签模板
            this.CheckTheLabelAnswers(
              this.labelManagement.stems[this.active].caseId
            );
            //获取列表
            tagActivitySpecialist({
              topicArray: [this.labelManagement.stems[this.active].caseId],
              labelId: this.labelManagement.templateId,
            }).then((res) => {
              this.referenceVersion = res.data;
              if (this.referenceVersion.length <= 0) {
                this.labelManagement.standardAnswers = "";
              } else {
                var num = 0;
                this.referenceVersion.map((item, index) => {
                  if (this.labelManagement.standardAnswer == item.id) {
                    num++;
                  }
                });
                if (num > 0) {
                  this.labelManagement.standardAnswers =
                    this.labelManagement.standardAnswer;
                  this.ChangeReferenceVersion(
                    this.labelManagement.standardAnswers
                  );
                } else {
                  this.labelManagement.standardAnswers =
                    this.referenceVersion[0].id;
                  this.ChangeReferenceVersion(
                    this.labelManagement.standardAnswers
                  );
                }
              }
            });
          }

          return;
        }
      }

      if (val !== old) {
        let param = {
          caseType: 0, // 题型
          isComplete: 0, // 是否完成 0 没有 1 有
        };
        let a = null;
        val.users.map((item) => {
          if (item.userId == this.appIdUser) {
            if (item.userType == 2) {
              this.helpUser = "帮扶";
            }
          }
        });
        if (this.status == 0) {
          param.activeId = val.stems[0].activeId;
          param.caseId = val.stems[0].caseId;
          param.taskId = val.stems[0].taskId;
          this.active = val.stems.length;
          this.getData(param);
        } else if (a == "帮扶") {
          this.completionStatus = true;
          param.activeId = val.stems[0].activeId;
          param.caseId = val.stems[0].caseId;
          param.taskId = val.stems[0].taskId;
          this.active = val.stems.length;
          this.getData(param);
        } else if (this.status == 1) {
          //是否完成所有任务
          let complete = val.stems.filter((item) => {
            return item.isComplete == 1;
          });
          if (this.$route.query.task) {
            this.completionStatus = false;
            param.isComplete = 0;
            param.activeId = this.$route.query.id;
            param.caseId = this.$route.query.caseId;
            param.taskId = this.$route.query.task;
            let b = null;
            val.stems.find((item, index) => {
              if (item.caseId == this.$route.query.caseId) {
                b = index;
                return index;
              }
            });
            this.subjectId = b;
            this.active = complete.length;
            this.getData(param);
          } else if (this.$route.query.taskId) {
            if (complete.length !== val.stems.length) {
              this.showGoOn = true;
            } else {
              this.completionStatus = true;
            }

            this.active = complete.length;
            param.isComplete = 1;
            param.activeId = this.$route.query.id;
            param.caseId = this.$route.query.caseId;
            param.taskId = this.$route.query.taskId;
            let b = null;
            val.stems.find((item, index) => {
              if (item.caseId == this.$route.query.caseId) {
                b = index;
                return index;
              }
            });
            this.subjectId = b;
            if (this.subjectId == val.stems.length - 1) {
              this.showNextQuestion = true;
            }
            this.getData(param);
          } else {
            if (complete.length !== val.stems.length) {
              //没完成
              this.completionStatus = false;
              this.active = complete.length;
              param.isComplete = 0;
              for (let i = 0; i < val.stems.length; i++) {
                if (val.stems[i].isComplete == 0) {
                  param.activeId = val.stems[i].activeId;
                  param.caseId = val.stems[i].caseId;
                  param.taskId = val.stems[i].id;
                  break;
                }
              }
              this.getData(param);
            } else {
              this.completionStatus = true;
              param.isComplete = 1;
              this.active = complete.length;
              param.isComplete = 1;
              for (let i = 0; i < val.stems.length; i++) {
                if (val.stems[i].isComplete == 1) {
                  param.activeId = val.stems[0].activeId;
                  param.caseId = val.stems[0].caseId;
                  param.taskId = val.stems[0].id;
                  break;
                }
              }
              this.getData(param);
              this.signOut();
            }
          }
          if (this.helpUser == "帮扶") {
            this.completionStatus = true;
          }
          if (this.ProfessorOrNot) {
            this.showButton = false;
          }
        }
      }
      if (localStorage.answer_time) {
        let arr = [];
        let key =
          this.$route.query.id + "~" + this.caseId + "~" + this.appIdUser;
        // 如果本地有
        arr = JSON.parse(localStorage.getItem("answer_time"));
        let isKey = null;
        let isValue = null;
        arr.forEach((item, index) => {
          Object.keys(item).forEach((item) => {
            if (item == key) {
              isKey = arr[index];
              isValue = arr[index][item];
            }
          });
        });
        // 是当前的题目
        if (isKey) {
          this.totalTime1 = isKey[key];
        }
      }
    },
    questions(val, old) {
      if (val !== old) {
        val.map((item) => {
          let firstChoice = [];
          let secondChoice = [];
          item.choices.map((itm) => {
            if (itm.count) {
              let arr = itm.count.split(",");
              arr.forEach((it) => {
                if (it == "1") {
                  firstChoice.push(itm.sn);
                }
                if (it == "2") {
                  secondChoice.push(itm.sn);
                }
              });
            }
          });
          item.firstChoice = firstChoice.join("、");
          item.secondChoice = secondChoice.join("、");
        });
      }
    },
  },
  created() {
    this.analysis();
  },
  methods: {
    //标签选择参考答案
    ChangeReferenceVersion(val) {
      tagActivityList({
        labelId: this.labelManagement.templateId,
        resourceId: this.labelManagement.stems[this.active].caseId,
        userId: val,
      }).then((res) => {
        setTimeout(() => {
          this.switchover(this.tagTemplateData);
          this.CheckTheLabelAnswerdata(this.tagTemplateData, res.data.list, 2); //this.tagTemplateData标签模板 //res.data.list 答案
          this.resourceId = 0; //题目id
          this.resourceId = val; //题目id
        }, 200);
      });
    },
    //切换参考答案隐藏所有解析
    switchover(val) {
      if (val.length > 0) {
        val.map((item) => {
          this.openId.push(item.id);
          // 1数值型，2多选型，2单选型，4文本型  typeof判断数据类型
          if (item.tag.length > 0) {
            item.tag.map((tag) => {
              tag["onAnalysis"] = {
                show: 0,
                con: 0,
              };
            });
          }
          if (item.children.length > 0) {
            this.switchover(item.children);
          }
        });
      }
      this.tagTemplateData = this.requestTagTemplateData;
    },
    //查看专家解析并记录
    changExpertAnalysis(tag) {
      var obj = {
        resourceId: this.labelManagement.stems[this.active].caseId,
        templateId: this.labelManagement.templateId,
        userId: Number(this.appIdUser),
        specialistId: this.labelManagement.standardAnswers,
      };
      tagExamineAnalysis(obj).then((res) => {});
    },
    //答题选择后触发
    changeLableData(val) {
      this.NoAnswerNum = 0;
      this.IsAnswer(this.tagTemplateData);
    },
    //判断标签答完没
    IsAnswer(e) {
      if (e.length > 0) {
        e.map((item) => {
          if (item.tag.length > 0) {
            if (item.switchValue == 1) {
              item.tag.map((tag) => {
                if (
                  tag.answer == "" ||
                  tag.answer == [] ||
                  tag.answer == undefined
                ) {
                  if (tag.answer == 0 && tag.type == 1) {
                  } else {
                    this.NoAnswerNum++;
                  }
                }
              });
            }
          }
          if (item.children.length > 0) {
            this.IsAnswer(item.children);
          }
        });
      }
      if (this.NoAnswerNum > 0) {
        this.showButton = true;
      } else {
        this.showButton = false;
      }
    },
    //标签提交 提取答案
    extractAnswer(e) {
      if (e.length > 0) {
        e.map((item) => {
          if (item.tag.length > 0) {
            item.tag.map((tag) => {
              if (tag.type == 3) {
                tag.answer = tag.answer.join(",");
              }
              var obj = {
                labelId: tag.id,
                answer: tag.answer,
                type: tag.type,
                analysis: tag.analysis,
                analysisView: tag.onAnalysis.con,
              };
              this.tagTemplateDataanswer.push(obj);
            });
          }

          if (item.children.length > 0) {
            this.extractAnswer(item.children);
          }
        });
      }
    },
    //标签提交
    SubmitTheAnswer() {
      if (this.ProfessorOrNot == "提交参考答案") {
        var answerType = "0";
      } else if (this.ProfessorOrNot == "提交答案") {
        var answerType = "1";
      }
      this.tagTemplateDataanswer = [];
      this.extractAnswer(this.tagTemplateData);

      var submit = {
        answerType: answerType,
        list: this.tagTemplateDataanswer,
        templateId: this.labelManagement.templateId, //标签模板id   classId班级id
        activeId: this.labelManagement.Id, //活动id
        classId: this.labelManagement.classId, //班级id
        taskId: this.labelManagement.stems[this.active].id, //
        resourceId: this.labelManagement.stems[this.active].caseId, //"resourceId":"1426", //题目id  caseId
      };
      tagactivitysubmit(submit).then((res) => {
        if (res.code == 0) {
          this.allow_go = true;
          this.loading = false; //提交完答案放行
          this.active = this.active + 1;
          //清空表单  获取标签
          this.tagTemplateData = [];
          this.Processinglabeldata(this.requestTagTemplateData);

          this.showButton = true; //提交按钮是否可以打开
          if (this.active == this.labelManagement.stems.length) {
            this.$message({
              type: "success",
              message: "全部答题完毕!",
            });
            this.signOut();
          } else {
            this.$message({
              type: "success",
              message: "提交完成,进入下一题!",
            });
            this.$el.querySelector(`#label`).scrollIntoView({
              behavior: "smooth", // 平滑过渡
              block: "start", // 上边框与视窗顶部平齐。默认值
            });
            this.caseSN = this.labelManagement.stems[this.active].stemName;
            resourceDetail({
              //获取最新的题
              id: this.labelManagement.stems[this.active].caseId,
            }).then((res) => {
              if (res.data.keyword == "NULL") {
                this.keyword = [];
              } else {
                this.keyword = res.data.keyword.split(",");
              }
              this.labelManagement.SubjectAnalytical = res.data.analysis;
              this.Tagquestions = res.data.topic;
            });
          }
        }
      });
    },
    //处理这种数据刚进入就调用,获取标签模板-----------------------------
    ObtainingLabelTemplate(val) {
      templateDetail({ id: val }).then((res) => {
        this.labelManagement.templateName = res.data.name;
        this.requestTagTemplateData = res.data.label;
        this.Processinglabeldata(this.requestTagTemplateData); //zzzz处理这组数据
      });
    },
    //处理标签数据
    Processinglabeldata(val) {
      if (val.length > 0) {
        val.map((item) => {
          this.openId.push(item.id);
          // 1数值型，2多选型，2单选型，4文本型  typeof判断数据类型
          if (item.tag.length > 0) {
            item.tag.map((tag) => {
              tag["onAnalysis"] = {
                show: 0,
                con: 0,
              };
              tag.analysis = "";
              if (tag.type == 3) {
                //多选
                var arr = [];
                tag.answer = [];
                tag.numberLowerLimit = null;
                tag.numberSupperLimit = null;
                if (tag.content.length > 0 && tag.content != null) {
                  if (!(tag.content instanceof Array)) {
                    //判断是不是数组，不是数组处理成数组
                    var e = tag.content.split(",");
                    e.map((item, index) => {
                      arr.push({
                        id: index + 1,
                        name: item,
                      });
                    });
                    tag.content = arr;
                  }
                }
              } else if (tag.type == 2) {
                //单选
                var arr = [];
                tag.answer = "";
                tag.numberLowerLimit = null;
                tag.numberSupperLimit = null;
                if (tag.content.length > 0) {
                  if (!(tag.content instanceof Array)) {
                    var e = tag.content.split(",");
                    e.map((item, index) => {
                      arr.push({
                        id: index + 1,
                        name: item,
                      });
                    });
                    tag.content = arr;
                  }
                }
              } else if (tag.type == 4) {
                tag.numberLowerLimit = null;
                tag.numberSupperLimit = null;
                tag.answer = "";
              } else if (tag.type == 1) {
                if (!(tag.content instanceof Array)) {
                  var e = tag.content.split("~");
                  tag.answer = Number(e[0]);
                  tag.numberLowerLimit = Number(e[0]);
                  tag.numberSupperLimit = Number(e[1]);
                  tag.content = e;
                } else {
                  tag.answer = tag.numberLowerLimit;
                }
              }
            });
          }
          if (item.children.length > 0) {
            this.Processinglabeldata(item.children);
          }
        });
      }
      this.tagTemplateData = this.requestTagTemplateData;
    },
    //  查看当前答案
    CheckTheLabelAnswers(val) {
      this.caseSN = this.labelManagement.stems[this.active].stemName;
      resourceDetail({
        //获取最新的题
        id: val,
      }).then((res) => {
        if (res.data.keyword == "NULL") {
          this.keyword = [];
        } else {
          this.keyword = res.data.keyword.split(",");
        }
        this.labelManagement.SubjectAnalytical = res.data.analysis;
        this.Tagquestions = res.data.topic;
      });
      tagActivityList({
        activeId: this.labelManagement.Id,
        resourceId: val,
      }).then((res) => {
        setTimeout(() => {
          this.CheckTheLabelAnswerdata(this.tagTemplateData, res.data.list, 1); //this.tagTemplateData标签模板 //res.data.list 答案
          this.resourceId = 0; //题目id
          this.resourceId = val; //题目id
        }, 200);
      });
    },
    //获取答案list 答案处理
    CheckTheLabelAnswerdata(tagTemplateData, answer, type) {
      if (tagTemplateData.length > 0) {
        tagTemplateData.map((item) => {
          if (item.tag.length > 0) {
            item.tag.map((tag) => {
              if (answer) {
                answer.map((answer) => {
                  if (answer.labelId == tag.id) {
                    if (answer.type == 3) {
                      //字符串要转换成数组   tag.answer = tag.answer.join(",");
                      answer.answer = answer.answer.split(",");
                    }
                    if (this.completionStatus) {
                      if (type == 1) {
                        //1表示看自己，2表示看专家参考答案
                        tag.answer = answer.answer;
                        tag.analysis = answer.analysis;
                      }
                      if (type == 2) {
                        tag["referenceVersion"] = answer.answer;
                        tag["ExpertAnalysis"] = answer.analysis;
                      }
                    } else {
                      if (type == 2) {
                        tag["ExpertAnalysis"] = answer.analysis;
                      }
                    }
                    // if (this.ProfessorOrNot = "提交参考答案") {
                    //    tag.analysis = answer.analysis;
                    // }
                  }
                });
              } else {
                tag["referenceVersion"] = null;
                tag["ExpertAnalysis"] = null;
              }
            });
          }

          if (item.children.length > 0) {
            this.CheckTheLabelAnswerdata(item.children, answer, type);
          }
        });
      }
    },
    // 选中查看错题开关
    switchChange(val) {
      if (!this.switchValue) {
        let arr = [];
        this.questions.map((item) => {
          item.choices.map((itm) => {
            if (itm.state !== null) {
              item.userAnswer = [itm.sn];
            }
          });
          if (item.userAnswerResult == 1) {
            arr.push(item);
          }
        });
        if (this.status == 0) {
          this.questions = arr;
        } else {
          this.wrongQuestions = arr;
        }
      } else if (this.switchValue) {
        if (this.status == 0) {
          this.questions = this.backupsQuestions;
        } else {
          this.wrongQuestions = backupsQuestions;
        }
      }
    },
    // 解析appIds
    analysis() {
      if (this.utils.identity("新建K12活动")) {
        this.status = 0;
      }
      if (this.utils.identity("K12做题")) {
        this.status = 1;
      }
      if (this.status == 0) {
        this.showButton = false;
        this.completionStatus = true;
      }
      if (this.$route.query.stemType == 2) {
        var urlAppId = window.location.href.split("=")[2].split("#")[0];
        let appIds = JSON.parse(localStorage.getItem("appIds"));
        if (appIds) {
          let target = appIds.find((item) => {
            return item.appId === urlAppId;
          });
          this.appIdUser = target.userId;
          if (this.utils.identity("提交答案")) {
            this.ProfessorOrNot = "提交答案";
          }
          if (this.utils.identity("提交参考答案")) {
            this.ProfessorOrNot = "提交参考答案";
          }
        }
      }
    },
    // K12老师选择学生
    searchStudent(val) {
      this.showSwitch = true;
      this.viewStudents = true;
      this.studentId = val;
      if (this.subjectId < this.active) {
        let param = {
          caseType: 0, // 题型
          isComplete: 1, // 是否完成 0 没有 1 有
          caseId: this.knowledge.stems[this.subjectId].caseId,
          activeId: this.knowledge.stems[this.subjectId].activeId,
          userId: this.studentId,
          taskId: this.knowledge.stems[this.subjectId].id,
        };
        this.getData(param);
      }
    },
    // K12老师查看下一题与自己查看
    nextSubject() {
      if (this.$route.query.stemType == 2) {
        if (this.ProfessorOrNot) {
          //代表标签答题
          this.active = this.active + 1;
          if (this.active >= this.labelManagement.stems.length) {
            this.$message.error("已经是最后一题了!");
            return;
          } else {
            tagActivitySpecialist({
              topicArray: [this.labelManagement.stems[this.active].caseId],
              labelId: this.labelManagement.templateId,
            }).then((res) => {
              this.referenceVersion = res.data;
              if (this.referenceVersion.length <= 0) {
                this.labelManagement.standardAnswers = "";
              } else {
                var num = 0;
                this.referenceVersion.map((item, index) => {
                  if (this.labelManagement.standardAnswer == item.id) {
                    num++;
                  }
                });
                if (num > 0) {
                  this.labelManagement.standardAnswers =
                    this.labelManagement.standardAnswer;
                } else {
                  this.labelManagement.standardAnswers =
                    this.referenceVersion[0].id;
                  this.ChangeReferenceVersion(
                    this.labelManagement.standardAnswers
                  );
                }
              }
            });
            this.CheckTheLabelAnswers(
              this.labelManagement.stems[this.active].caseId
            );
            if (this.labelManagement.standardAnswer) {
              this.ChangeReferenceVersion(this.labelManagement.standardAnswer);
            }
          }
          this.$el.querySelector(`#label`).scrollIntoView({
            behavior: "smooth", // 平滑过渡
            block: "start", // 上边框与视窗顶部平齐。默认值
          });
          return;
        }
      }

      this.getUserSpendTime();
      if (this.subjectId !== this.active - 1) {
        this.subjectId = this.subjectId + 1;
      }
      if (this.studentId !== null) {
        if (this.subjectId < this.active) {
          let param = {
            activeId: this.knowledge.stems[this.subjectId].activeId,
            caseId: this.knowledge.stems[this.subjectId].caseId,
            caseType: 0, // 题型
            isComplete: 1, // 是否完成 0 没有 1 有
            taskId: this.knowledge.stems[this.subjectId].id,
            userId: this.studentId,
          };
          this.getData(param);
        }
        if (this.subjectId == this.active - 1) {
          this.$message.error("已经是最后一题了!");
        }
      } else {
        if (this.subjectId < this.active) {
          let param = {
            activeId: this.knowledge.stems[this.subjectId].activeId,
            caseId: this.knowledge.stems[this.subjectId].caseId,
            caseType: 0, // 题型
            isComplete: 1, // 是否完成 0 没有 1 有
            taskId: this.knowledge.stems[this.subjectId].id,
          };
          this.getData(param);
        }
        if (this.subjectId == this.active - 1) {
          this.$message.error("已经是最后一题了!");
        }
        if (this.helpUser == "帮扶") {
          if (this.knowledge.stems[this.subjectId]) {
            let param = {
              activeId: this.knowledge.stems[this.subjectId].activeId,
              caseId: this.knowledge.stems[this.subjectId].caseId,
              caseType: 0, // 题型
              isComplete: 0, // 是否完成 0 没有 1 有
              taskId: this.knowledge.stems[this.subjectId].id,
            };
            this.getData(param);
            this.showSwitch = false;
          } else {
            this.$message.error("已经是最后一题了!");
          }
        }
      }
      this.$nextTick(() => {
        var anchor = document.querySelector("#scroll");
        if (anchor) {
          anchor.scrollIntoView({ behavior: "smooth", block: "start" });
        }
        setTimeout(() => {
          let div = this.$refs["elscrollbar"].$refs["wrap"];
          let el = document.getElementById("mores"); //滚动到哪
          let top = el.offsetTop;
          div.scrollTo({ behavior: "smooth", top: top });
        }, 300);
      });
    },
    // 答完题之后前往下一题
    nextQuestion() {
      this.answer = {};
      this.centerDialogVisible = false;
      this.showSwitch = false;
      this.ISvariant = true;
      this.totalTime1 = 0;
      let arr = [];
      this.knowledge.stems.map((item) => {
        let obj = {};
        obj["caseId"] = item.caseId;
        obj["activeId"] = item.activeId;
        obj["caseType"] = 0;
        obj["isComplete"] = 0;
        obj["taskId"] = item.id;
        arr.push(obj);
      });
      if (this.active < arr.length) {
        this.getData(arr[this.active]);
      }
      this.showGoOn = false;
    },
    // 对比学习答案
    contrast() {
      let arr = [];
      this.knowledge.stems.map((item) => {
        let obj = {};
        obj["caseId"] = item.caseId;
        obj["activeId"] = item.activeId;
        obj["taskId"] = item.id;
        arr.push(obj);
      });
      this.$router.push({ path: "/comparative", query: arr[this.subjectId] });
    },
    // 答完题之后查看当前答案
    viewAnswers() {
      this.centerDialogVisible = false;
      this.showGoOn = true;
      let arr = [];
      this.knowledge.stems.map((item) => {
        let obj = {};
        obj["caseId"] = item.caseId;
        obj["activeId"] = item.activeId;
        obj["taskId"] = item.id;
        arr.push(obj);
      });
      // 未答完题目时查看当前答案
      this.$router.push({
        path: "/comparative",
        query: {
          id: arr[this.active - 1].activeId,
          show: 1,
          caseId: arr[this.active - 1].caseId,
        },
      });
      // 最后一道题答完查看答案
      if (this.active == arr.length) {
        this.showNextQuestion = true;
      }
    },
    //提交
    submit() {
      // 点击提交答案放行条件判否
      this.allow_go = false;
      this.$confirm("您确定提交吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //判断是不是标签提交
          if (this.$route.query.stemType == 2) {
            if (this.ProfessorOrNot) {
              this.SubmitTheAnswer();
              return;
            }
          }
          this.loading = true;
          if (this.throttleTimer === true) {
            this.throttleTimer = false;
            let paramArray = [];
            for (let key in this.answer) {
              let choicesArray = [];
              let number = 0;
              let case_item = this.questions.find((find_item) => {
                return find_item.id == key;
              });
              for (let i = 0; i < this.answer[key].length; i++) {
                case_item.choices.sort(function (a, b) {
                  return a.sn.localeCompare(b.sn, "zh");
                });
                case_item.choices.forEach((item, index) => {
                  if (item.id == this.answer[key][i]) {
                    number = number + Math.pow(2, index);
                    // case_arr.push(index)
                  }
                });
                choicesArray.push({
                  choiceid: this.answer[key][i],
                });
              }

              let case_id = this.questionAnswer.find((item) => {
                return item.id == key;
              });
              paramArray.push({
                choices: choicesArray,
                itemId: key,
                recordTime: this.count,
                questionAnswer: case_id.answer,
                userAnswer: number,
              });
            }
            if (this.noArr.length == 0) {
              this.paramsObj = {
                questions: paramArray,
                taskId: this.knowledge.stems[this.active].id, //任务id
                userActiveId: this.knowledge.id,
                startTime: this.startTime, //开始做题时间
                finishTime: new Date().getTime(), //提交时间
                classId: Number(this.$route.query.classId),
                caseId: this.caseId,
              };
            } else {
              this.paramsObj = {
                questions: paramArray,
                taskId: this.noArr[this.noActive].id, //任务id
                userActiveId: this.knowledge.id,
                startTime: this.startTime, //开始做题时间
                finishTime: new Date().getTime(), //提交时间
                classId: Number(this.$route.query.classId),
                caseId: this.caseId,
              };
            }
            let arr = [];
            this.knowledge.stems.map((item) => {
              let obj = {};
              obj["caseId"] = item.caseId;
              obj["activeId"] = item.activeId;
              obj["caseType"] = 0;
              obj["isComplete"] = 0;
              obj["taskId"] = item.id;
              arr.push(obj);
            });
            if (paramArray.length > 0) {
              this.$api.page.saveCaseQuestion(this.paramsObj).then((res) => {
                this.allow_go = true; //提交完答案放行
                this.loading = false; //提交完答案放行
                if (res.data.data == false) {
                  this.$message({
                    message: "您有题目做错了!请重新答题!",
                    type: "warning",
                  });
                  this.totalTime1 = 0;
                } else if (res.data.data == null) {
                  this.active = this.active + 1;
                  if (this.active <= arr.length) {
                    Message({
                      showClose: true,
                      message: "提交成功",
                      type: "success",
                    });
                    // this.centerDialogVisible = true;
                    arr[this.active - 1]["isComplete"] = 1;
                    this.getData(arr[this.active - 1], 0);
                  }
                  if (this.active == arr.length) {
                    this.showNext = false;
                  }
                  this.subjectId = this.subjectId + 1;
                  this.noActive = this.noActive + 1;
                } else if (
                  Number(this.$route.query.id) == res.data.data.activeId
                ) {
                  this.active = this.active + 1;
                  if (this.active <= arr.length) {
                    Message({
                      showClose: true,
                      message: "提交成功",
                      type: "success",
                    });
                    if (this.active < arr.length) {
                      this.getData(arr[this.active]);
                    }
                  }
                  // this.centerDialogVisible = true;
                  this.subjectId = this.subjectId + 1;
                  this.noActive = this.noActive + 1;
                  if (this.active < arr.length) {
                    this.getData(arr[this.active], 0);
                  }
                } else if (
                  Number(this.$route.query.id) != res.data.data.activeId
                ) {
                  this.caseType = res.data.data.caseType;
                  this.urgentId = res.data.data.activeId;
                  this.showDialog = true;
                }
                let userId = Number(this.utils.userId());
                _hmt.push([
                  "_trackEvent",
                  "提交答案",
                  "提交",
                  "变式名:" + this.caseSN,
                  userId,
                ]);
              });
              //总学习时长
              this.getUserSpendTime();
              // 重置时间和答案
              //完成后清空并且停止定时器
              this.totalTime1 = 0;
              this.count = 0;
              this.time = "00:00";
              this.selecteObj = {};
              this.answer = {};
            }
            this.showButton = true;
            this.$nextTick(() => {
              var anchor = document.querySelector("#scroll");
              if (anchor) {
                anchor.scrollIntoView({ behavior: "smooth", block: "start" });
              }
              setTimeout(() => {
                let div = this.$refs["elscrollbar"].$refs["wrap"];
                let el = document.getElementById("mores"); //滚动到哪
                let top = el.offsetTop;
                div.scrollTo({ behavior: "smooth", top: top });
              }, 300);
            });
            setTimeout(() => {
              this.throttleTimer = true;
            }, 2000);
          }
        })
        .catch(() => {
          this.allow_go = true;
        });
    },
    // 退出
    signOut() {
      this.centerDialogVisible = false;
      if (this.utils.identity("K12做题")) {
        this.answerTime();
      }
      this.$router.push({
        path: "/activityDetail",
        query: this.$route.query,
      });
    },
    //计时
    startHandler() {
      // this.ISvariant=true//控制上方
      this.IStotalTime1 = false;
      if (this.totalTime1 !== 0) {
      } else {
        this.totalTime1 = 0;
      }
      let set = setInterval(() => {
        this.totalTime1++;
        this.count++;
        this.time =
          (Math.floor(this.count / 60) > 9
            ? Math.floor(this.count / 60)
            : "0" + Math.floor(this.count / 60)) +
          ":" +
          (this.count % 60 > 9 ? this.count % 60 : "0" + (this.count % 60));
        if (this.IStotalTime1) {
          clearInterval(set);
          this.IStotalTime1 = false;
        }
      }, 1000);
    },
    //学生查看当前答题完成时间时间
    getData(params, type) {
      if (this.$route.query.stemType == 0) {
        this.caseId = params.caseId;
        this.$api.page.getCase(params).then((res) => {
          this.firstTime = res.data.data.firstTime;
          this.secondTime = res.data.data.secondTime; //第二次
          let a = Math.floor(this.secondTime / 60);
          if (a <= 9) {
            this.TWOtimer.two = "0" + a;
          } else {
            this.TWOtimer.two = a;
          }
          this.TWOtimer.three =
            this.secondTime % 60 > 9
              ? this.secondTime % 60
              : "0" + (this.secondTime % 60);
          if (res.data.code == 0) {
            this.questionAnswer = [];
            this.descriptions = res.data.data.descriptions;
            this.backupsQuestions = res.data.data.questions;
            this.questions = res.data.data.questions;
            this.caseSN = res.data.data.caseSN;
            this.questions.sort((a, b) => {
              //choices
              return a.displaySN - b.displaySN;
            });
            //处理正确答案
            for (let i = 0; i < this.questions.length; i++) {
              // 当前题目的正确答案
              let obj = {
                answer: null,
                id: null,
              };
              obj.answer = this.questions[i].answer;
              obj.id = this.questions[i].id;
              this.questionAnswer.push(obj);
              this.questions[i].choices = this.questions[i].choices.sort(
                (a, b) => {
                  return a.sn + "" > b.sn + "" ? 1 : -1;
                }
              );
              this.questions[i].answer = this.questions[i].choices.map(
                (a, i) => {
                  let str = null;
                  a.isAnswer == 1 ? (str = a.sn) : (str = null);
                  return str;
                }
              );
              this.questions[i].answer = this.questions[i].answer.filter(
                (item) => {
                  return item !== null;
                }
              );
            }
            if (this.status == 0) {
              //学生答题
              this.ISvariant = false;
            }
            if (this.status == 1 && !params.isComplete == 1) {
              //学生答题
              if (this.ISweiyi) {
                this.ISweiyi = false;
                this.startHandler();
              }
            }
            if (this.status == 1 && params.isComplete == 1) {
              // this.showSwitch = true;
              this.switchChange();
              this.ISvariant = false;
            }
            if (this.showSwitch == true) {
              this.switchChange();
            }
          } else {
            this.$message.error(res.data.message);
          }
          if (this.wrongQuestions.length > 0) {
            this.questionsMsg = "存在错误!";
          } else if (this.wrongQuestions.length == 0) {
            this.questionsMsg = "没有错误!";
          }
          if (type == 0) {
            this.centerDialogVisible = true;
          }
        });
      }
    },
    // 选择答案
    task(id1, id2, type) {
      if (
        this.status == 0 ||
        this.completionStatus ||
        this.helpUser == "帮扶"
      ) {
        return;
      }
      // 上传完答案之后才允许点击
      if (this.allow_go) {
        let multipleSelectFlag = null;
        if (type == 3) {
          multipleSelectFlag = true;
        } else {
          multipleSelectFlag = false;
        }
        let selectItem = this.answer[id1] == null ? [] : this.answer[id1]; //多选
        //单选
        if (!multipleSelectFlag) {
          selectItem = [];
        }
        if (selectItem.length > 0) {
          let isExist = false;
          for (let i = selectItem.length - 1; i >= 0; i--) {
            if (selectItem[i] == id2) {
              selectItem.splice(i, 1);
              isExist = true;
            }
          }
          if (!isExist) {
            selectItem.push(id2);
          }
        } else {
          if (
            multipleSelectFlag ||
            this.selecteObj[id1] == null ||
            (!multipleSelectFlag &&
              this.selecteObj[id1] != null &&
              this.selecteObj[id1][id2] == null)
          ) {
            selectItem.push(id2);
          }
        }
        this.$set(this.answer, id1, selectItem);
        // 判断是否全选
        if (Object.keys(this.answer).length == this.questions.length) {
          this.showButton = false;
        }
        for (let key in this.answer) {
          if (this.answer[key].length == 0) {
            this.showButton = true;
          }
        }
      }
    },
    //load
    load() {
      let id = this.$route.query.id;
      activeDetails({ id: id }).then((res) => {
        res.data.stems.map((item) => {
          item["title"] = item.stemId;
          if (item.isComplete == 0) {
            this.noArr.push(item);
          }
        });
        this.knowledge = res.data;
        this.taskAmount = res.data.stems.length;
      });
    },
    //答题总时长
    getUserSpendTime() {
      let params = {
        activeId: Number(this.$route.query.id),
        activeType: 0,
      };
      this.$api.page.getUserSpendTime(params).then((res) => {
        if (res.data) {
        }
      });
    },
    // 跳转更紧急任务
    jump() {
      this.$router.push({
        path: "/activityDetail",
        query: {
          id: this.urgentId,
          classId: this.$route.query.classId,
          stemType: this.caseType,
        },
      });
      this.showDialog = false;
    },
    // 非对比学习功能，完成后查看答案
    all_finish(val, param) {
      this.completionStatus = true;
      param.isComplete = 1;
      this.active = complete.length;
      for (let i = 0; i < val.stems.length; i++) {
        if (val.stems[i].isComplete == 1) {
          param.activeId = val.stems[0].activeId;
          param.caseId = val.stems[0].caseId;
          param.taskId = val.stems[0].id;
          break;
        }
      }
      this.getData(param);
    },
    // 本地保存时间
    answerTime(val) {
      let arr = [];
      let key = this.$route.query.id + "~" + this.caseId + "~" + this.appIdUser;
      // 如果本地没有
      if (!localStorage.answer_time) {
        let obj = {};
        obj[key] = this.totalTime1;
        arr.push(obj);
      } else {
        // 如果本地有
        arr = JSON.parse(localStorage.getItem("answer_time"));
        let isKey = null;
        let isValue = null;
        arr.forEach((item, index) => {
          Object.keys(item).forEach((item) => {
            if (item == key) {
              isKey = arr[index];
              isValue = arr[index][item];
            }
          });
        });
        // 是当前的题目
        if (isKey) {
          isKey[key] = this.totalTime1;
        } else {
          let obj = {};
          obj[key] = this.totalTime1;
          arr.push(obj);
        }
      }
      localStorage.setItem("answer_time", JSON.stringify(arr));
    },
    // 禁止取消弹窗
    handleClose() {},
  },
};
</script>
<style lang='less' scoped>
@import "../../style/subject/subject.less";
@import "../../style/timer/timer.less";
@import "../../style/mouse.less";
.class3 {
  background: #d8e8ff !important;
  box-shadow: 0px 2px 4px 0px #dcedff;
}
.class4 {
  background: green;
  box-shadow: 0px 2px 4px 0px #dcedff;
}
.des /deep/ p {
  text-indent: 1em;
  line-height: 35px;
  font-size: 12px;
  font-weight: 400;
  color: #323232;
}
.des /deep/ span {
  // text-indent: 1em;
  // display: inline-block;
  // float: left;
  line-height: 35px;
  // font-size: 12px;
  // font-weight: 400;
  // color: #323232;
}
.des/deep/img {
  // vertical-align: text-top;
  // width: 95%;
}
.des_p {
  text-indent: 1em;
  line-height: 35px;
  font-size: 12px;
  font-weight: 400;
  color: #323232;
  background-color: #d8e8ff;
  border-radius: 5px;
  padding: 10px 0;
}
.stemContent/deep/img {
  vertical-align: middle;
}
.choices_span {
  border: none !important;
  display: inline-block !important;
  padding-left: 15px;
  width: 95%;
  line-height: 30px;
  /deep/table {
    width: 100% !important;
  }
}
.choices/deep/p {
  padding-left: 20px;
  & img {
    height: 100%;
    // vertical-align: middle;
  }
}
.el-scrollbar/deep/.el-scrollbar__wrap {
  overflow-x: hidden;
}
.topic {
  margin-top: 20px;
  padding: 10px;
  border: 2px solid rgb(223, 223, 223);
  border-radius: 5px;
  position: relative;
}
//标签的样式
.topic1 {
  margin-top: 20px;
  padding: 10px;
  border: 2px solid rgb(223, 223, 223);
  span {
    background-color: rgb(223, 223, 223);
  }
  .topic1-content {
    padding-left: 20px;
    font-size: 12px;
    font-weight: 600;
    color: #323232;
    line-height: 17px;
  }
  .topic2 {
    margin-top: 40px;
    padding-left: 10%;
    font-size: 12px;
    font-weight: 600;
    color: #323232;
    line-height: 17px;
    .topic3 {
      font-size: 12px;
      font-weight: 600;
      color: #323232;
      line-height: 17px;
      margin-top: 20px;
      margin-left: 10%;
    }
  }
  border-radius: 5px;
  position: relative;
}

.resize {
  display: inline-block;
  width: 8px;
  background-color: #f6f6f6;
  height: 670px;
  cursor: pointer;
}
.questionsMsgSpan {
  display: block;
  margin: 0 auto;
  text-align: center;
}
.biaoqian {
  border: 1px solid red;
}
.sn_span {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  border: none !important;
}
/deep/.caseSN_tag {
  background-color: #ecf5ff !important;
  border-color: #d9ecff !important;
  display: inline-block !important;
  height: 32px !important;
  padding: 0 10px !important;
  line-height: 30px !important;
  font-size: 12px !important;
  color: #409eff !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-radius: 4px !important;
  box-sizing: border-box !important;
  white-space: nowrap !important;
  margin-right: 10px !important;
}
.caseSN_keyword {
  height: 32px !important;
  line-height: 32px !important;
}
</style>
