//import axios from 'axios'
import axios from "@/commons/libs/request.js";
// const API_ROOT = 'http://793c.c.lsctl.com/api/'
// const API_SSO = "http://sso.i.lsctl.com/api/"

const API_ROOT = process.env.NODE_ENV == 'development' ? 'http://793c.c.lsctl.com/api/' : '../api/';
const API_SSO = process.env.NODE_ENV == 'development' ? 'http://sso.i.lsctl.com/api/' : "../sso/api/"
const API_BQ = 'http://793c.c.lsctl.com/api/'

// 活动
export const activeAdd = params => {
    return axios.post(`${API_ROOT}active/add`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //添加活动
export const activeUpdate = params => {
    return axios.post(`${API_ROOT}active/update`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //编辑活动
export const activeDelete = params => {
    return axios.get(`${API_ROOT}active/delete`, {
        params: params
    }).then(res => res.data).catch(error => {
        console.log(error)
    });
} //删除活动
export const activeList = params => {
    return axios.post(`${API_ROOT}active/list`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //活动列表
export const activeDetails = params => {
    return axios.get(`${API_ROOT}active/details`, {
        params: params
    }).then(res => res.data).catch(error => {
        console.log(error)
    });
} //活动详情
export const specialTopicByStudent = params => {
    return axios.get(`${API_ROOT}activeUserComment/specialTopicByStudent`, {
        params: params
    }).then(res => res.data).catch(error => {
        console.log(error)
    });
} //学生活动完成率
export const activeComment = params => {
    return axios.post(`${API_ROOT}activeComment/list`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //获取评论列表
export const activeCommentAddReply = params => {
    return axios.post(`${API_ROOT}activeComment/addReply`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //回复评论
export const activeCommentAdd = params => {
    return axios.post(`${API_ROOT}activeComment/add`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //添加评论
export const activeCommentDelete = params => {
    return axios.get(`${API_ROOT}activeComment/delete`, {
        params: params
    }).then(res => res.data).catch(error => {
        console.log(error)
    });
} //删除评论

// 任务
export const getTasksByIds = params => {
    return axios.post(`${API_ROOT}task/getTasksByIds`, {
        params: params
    }).then(res => res.data).catch(error => {
        console.log(error)
    });
} //任务详情
export const getSpecialtopic = params => {
    return axios.post(`${API_ROOT}specialtopic/getAllSpecialtopic`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //获取所有K12专题和变式
export const getSpecialtopicVideo = params => {
    return axios.post(`${API_ROOT}specialtopic/getAllSpecialtopic/video`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //获取所有视频有关的章节列表
export const getIMXTask = params => {
    return axios.post(`${API_ROOT}IMX/Task`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //获取所有immex专题和变式
export const activeGetSpecialtopic = params => {
    return axios.post(`${API_ROOT}specialtopic/getSpecialtopic`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //活动树获取专题和变式
export const getCase = params => {
    return axios.post(`${API_ROOT}task/getCase`, {
        params: params
    }).then(res => res.data).catch(error => {
        console.log(error)
    });
} //根据变式id得到变式的题目（k12系统）

// 搜索栏
export const classList = params => {
    return axios.post(`${API_ROOT}class/list`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //获取班级
export const userPerson = params => {
    return axios.post(`${API_ROOT}user/person`, {
        params: params
    }).then(res => res.data).catch(error => {
        console.log(error)
    });
} //个人中心
export const userUpdate = params => {
    return axios.post(`${API_ROOT}user/update`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //编辑个人中心
export const teacherList = params => {
    return axios.post(`${API_ROOT}user/TeacherList`, {
        params: params
    }).then(res => res.data).catch(error => {
        console.log(error)
    });
} //获取教师
export const StudentList = params => {
    return axios.post(`${API_ROOT}user/StudentList`, {
        params: params
    }).then(res => res.data).catch(error => {
        console.log(error)
    });
} //获取所有学生
export const GetStudentByGradeClass = params => {
    return axios.post(`${API_ROOT}user/GetUserByGradeClass `, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //获取小组的学生

// 新增班级
export const addClass = params => {
    return axios.post(`${API_ROOT}class/add`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //新增班级
export const updateClass = params => {
    return axios.post(`${API_ROOT}class/update`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //编辑班级
export const removeClass = params => {
    return axios.post(`${API_ROOT}class/remove`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //删除班级
export const groupList = params => {
    return axios.post(`${API_ROOT}group/list`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //获取班级下的群组
export const removeGroup = params => {
    return axios.post(`${API_ROOT}group/remove`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //删除小组
export const addGroup = params => {
    return axios.post(`${API_ROOT}group/add`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //添加小组
export const updateGroup = params => {
    return axios.post(`${API_ROOT}group/update`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //编辑小组
export const UserIntoClassByCode = params => {
    return axios.post(`${API_ROOT}user/UserIntoClassByCode`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //根据班级编码加入班级
export const addUser = params => {
    return axios.post(`${API_ROOT}user/add`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //班级添加学生
export const userExitClass = params => {
    return axios.post(`${API_ROOT}user/UserExitClass`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //班级移除学生
export const addSsoByStudent = params => {
    return axios.post(`${API_ROOT}user/addSsoByStudent  `, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //班级批量添加学生验证sso
export const UserIntoClassByCodes = params => {
    return axios.post(`${API_ROOT}user/UserIntoClassByCodes`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //班级批量添加学生

//学情
export const K12Analysis = params => {
    return axios.post(`${API_ROOT}analysis/K12`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //K12老师查询k12学情
export const getUserImmxStatisticsData = params => {
    return axios.post(`${API_ROOT}DataProcess/getUserImmxStatisticsData`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //老师查询immex学情
export const taskAnalysis = params => {
    return axios.post(`${API_ROOT}analysis/Task`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //查询K12老师任务概况
export const StudentTask = params => {
    return axios.post(`${API_ROOT}analysis/StudentTask `, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //查询学生任务概况
export const IntegralStatistics = params => {
    return axios.post(`${API_ROOT}analysis/IntegralStatistics`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //班级积分榜排名统计
export const analysisSourceIntegral = params => {
    return axios.post(`${API_ROOT}analysis/SourceIntegral`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //班级积分来源
export const studentByIdAnalysis = params => {
    return axios.post(`${API_ROOT}analysis/StudentById`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //班级内化率
export const SpecialNPCasesList = params => {
    return axios.post(`${API_ROOT}analysis/SpecialNPCasesList `, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //学生的专题变式列表
export const SpecialList = params => {
    return axios.post(`${API_ROOT}analysis/SpecialList`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //学生的专题活动列表

export const SpecialListByStudentAnalysis = params => {
    return axios.post(`${API_ROOT}analysis/SpecialListByStudentAnalysis`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //学生的学情分析活动统计
export const SpecialListByStudent = params => {
    return axios.post(`${API_ROOT}analysis/SpecialListByStudent`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //学生的学情分析活动统计点击进入的详情
export const ResultByTaskId = params => {
    return axios.post(`${API_ROOT}analysis/Students/ResultByTaskId`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //老师查询学生的答题详情
export const SectionAnalysisTable = params => {
    return axios.post(`${API_ROOT}analysis/SectionAnalysisTable`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //老师按章节查询学生的答题详情
export const SectionAnalysisDetails = params => {
    return axios.post(`${API_ROOT}analysis/SectionAnalysisDetails`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //老师按章节查询学生的答题详情(具体到学生)
export const ArrangeChapter = params => {
    return axios.post(`${API_ROOT}analysis/ArrangeChapter`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //老师查询布置过的章节


export const getAllEfficiency = params => {
    return axios.post(`${API_ROOT}DataProcess/getAllEfficiency`, params).then(res => res.data).catch(error => {
        console.log(error)
    })
} //immex四象限
export const ActivityPercentageList = params => {
    return axios.post(`${API_ROOT}analysis/ActivityPercentageList`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //K12的柱状图分析
export const ClassNPCasesAccuracy = params => {
    return axios.post(`${API_ROOT}analysis/ClassNPCasesAccuracy`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //K12详情班级分析
export const StudyStatistics = params => {
    return axios.post(`${API_ROOT}analysis/StudyStatistics`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //老师学情变式统计表
export const StudyStatisticStudent = params => {
    return axios.post(`${API_ROOT}analysis/StudyStatisticStudent`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //学生学情变式统计表
export const StudentStatisticsDetails = params => {
    return axios.post(`${API_ROOT}analysis/StudentStatisticsDetails`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //变式学生详情表
export const StudentStudyDetail = params => {
    return axios.post(`${API_ROOT}analysis/StudentStudyDetail`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //针对性图表


//老师查看标签学情相关
export const LabelActive = params => {
    return axios.post(`${API_ROOT}analysis/class/label/active`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //标签活动列表
export const LabelMessage = params => {
    return axios.post(`${API_ROOT}analysis/class/label/message`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //标签活动列表




// 错题本
export const WrongTopic = params => {
    return axios.post(`${API_ROOT}analysis/WrongTopic`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //错题本的错误变式
export const mistakesList = params => {
    return axios.post(`${API_ROOT}mistakes/list`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //K12详情班级分析
export const mistakesExport = params => {
    return axios.post(`${API_ROOT}mistakes/export`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //导出全部错题本pdf

// 站内信
export const messageAdd = params => {
    return axios.post(`${API_ROOT}message/add`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //发送信息
export const messageList = params => {
    return axios.post(`${API_ROOT}message/list`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //信息列表
export const messageUpdate = params => {
    return axios.post(`${API_ROOT}message/update`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //修改信息状态
export const messageRemove = params => {
    return axios.post(`${API_ROOT}message/remove`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //删除信息

// 资料库
export const templateAdd = params => {
    return axios.post(`${API_ROOT}tag/manage/template/add`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //新建标签模板

export const templateList = params => {
    return axios.post(`${API_ROOT}tag/manage/template/list`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //标签模板列表

export const templateRemove = params => {
    return axios.post(`${API_ROOT}tag/manage/template/remove`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //标签模板删除

export const templateDetail = params => {
    return axios.post(`${API_ROOT}tag/manage/template/detail`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //标签模板详情

export const templateUpdate = params => {
    return axios.post(`${API_ROOT}tag/manage/template/update`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //标签模板编辑


// 导入题目
export const resourceAdd = params => {
    return axios.post(`${API_ROOT}tag/manage/resource/add`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //题目新建
export const resourceList = params => {
    return axios.post(`${API_ROOT}tag/manage/resource/list`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //题目列表
export const resourceRemove = params => {
    return axios.post(`${API_ROOT}tag/manage/resource/remove`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //题目删除

export const resourceDetail = params => {
    return axios.post(`${API_ROOT}tag/manage/resource/detail`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //题目详情
export const resourceUpdate = params => {
    return axios.post(`${API_ROOT}tag/manage/resource/update`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //题目编辑


//k12出题
export const CaseAdd = params => {
    return axios.post(`${API_ROOT}Case/add`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //变式新增
export const CaseUpdate = params => {
    return axios.post(`${API_ROOT}Case/update`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //变式编辑
export const CaseList = params => {
    return axios.post(`${API_ROOT}Case/list`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //变式列表
export const CaseDelete = params => {
    return axios.post(`${API_ROOT}Case/delete`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //变式删除
export const CaseDetails = params => {
    return axios.get(`${API_ROOT}Case/details`, {
        params: params
    }).then(res => res.data).catch(error => {
        console.log(error)
    });
} //变式详情
export const CaseVariantUpdate = params => {
    return axios.post(`${API_ROOT}Case/update`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //变式编辑
export const updateContent = params => {
    return axios.post(`${API_ROOT}Case/update/content`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //变式绑定活动后可编辑

//专题
export const specialtopicList = params => {
    return axios.post(`${API_ROOT}specialtopic/list`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //专题列表
export const specialtopicAdd = params => {
    return axios.post(`${API_ROOT}specialtopic/add`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //专题新增
export const specialtopicUp = params => {
    return axios.post(`${API_ROOT}specialtopic/update`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //专题编辑
export const specialtopicDelete = params => {
    return axios.post(`${API_ROOT}specialtopic/delete`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //专题删除

export const allKeyword = params => {
    return axios.post(`${API_ROOT}tag/manage/resource/all/keyword`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //创建活动时的关键字题目







//标签

export const keywordTopic = params => {
    return axios.post(`${API_ROOT}tag/manage/resource/keyword/topic`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //关键字级联查询

//标签答题完毕，提交按钮
export const tagactivitysubmit = params => {
    return axios.post(`${API_ROOT}tag/activity/submit`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //提交按钮
//标签答题完毕，查看答案
export const tagActivityList = params => {
    return axios.get(`${API_ROOT}tag/activity/list`, {
        params: params
    }).then(res => res.data).catch(error => {
        console.log(error)
    });
} //提交按钮

//标签活动列表详情，当前班级下
export const activityFindActive = params => {
    return axios.post(`${API_ROOT}tag/activity/findActive`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //提交按钮
export const tagActivitySpecialist = params => {
    return axios.post(`${API_ROOT}tag/activity/specialist`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //添加活动时查询条件相符的专家
export const tagExamineAnalysis = params => {
    return axios.post(`${API_ROOT}tag/activity/examine/specialist/analysis`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //学生做题后查看是否解析


//试卷题目和试卷
//获取全部题目
export const examTopicAll = params => {
    return axios.post(`${API_ROOT}exam/topic/all`,
        params
    ).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//删除题目
export const examTopicDelete = params => {
    return axios.get(`${API_ROOT}exam/topic/delete`, {
        params: params
    }).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//添加题目
export const examTopicAdd = params => {
    return axios.post(`${API_ROOT}exam/topic/add`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//修改题目
export const examTopicUpdate = params => {
    return axios.post(`${API_ROOT}exam/topic/update`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//获取题目详情
export const examTopicMessage = params => {
    return axios.get(`${API_ROOT}exam/topic/message`, {
        params: params
    }).then(res => res.data).catch(error => {
        console.log(error)
    });
}

//试卷
//获取全部试卷
export const examList = params => {
    return axios.post(`${API_ROOT}exam/list`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//查看详情
export const examMessage = params => {
    return axios.get(`${API_ROOT}exam/message`, {
        params: params
    }).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//添加试卷
export const examAdd = params => {
    return axios.post(`${API_ROOT}exam/add`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//修改试卷
export const examUpdate = params => {
    return axios.post(`${API_ROOT}exam/update`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//提交试卷
export const studentExamSubmit = params => {
    return axios.post(`${API_ROOT}student/exam/submit`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//删除试卷
export const examDelete = params => {
    return axios.get(`${API_ROOT}exam/delete`, {
        params: params
    }).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//学生答完题获取详情
export const studentExamMessage = params => {
    return axios.get(`${API_ROOT}student/exam/message`, {
        params: params
    }).then(res => res.data).catch(error => {
        console.log(error)
    });
}


//职业教育题目管理
//新建
export const subjectAdd = params => {
    return axios.post(`${API_ROOT}subject/add`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//修改
export const subjectUpdate = params => {
    return axios.post(`${API_ROOT}subject/update`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//查询课程
export const subjectList = params => {
    return axios.post(`${API_ROOT}subject/list`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//详情
export const subjectDetail = params => {
    return axios.post(`${API_ROOT}subject/detail`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//删除
export const subjectDelete = params => {
    return axios.get(`${API_ROOT}subject/delete`, {
        params: params
    }).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//新建变式名称
export const themeCaseAddName = params => {
    return axios.post(`${API_ROOT}theme/case/add/name`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//修改变式名称
export const themeCaseUpdateName = params => {
    return axios.post(`${API_ROOT}theme/case/update/name`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//变式详情更改提交
export const themeCaseAddCaseDescribe = params => {
    return axios.post(`${API_ROOT}theme/case/add/case/describe`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
}

//变式详情
export const themeCaseDetail = params => {
    return axios.get(`${API_ROOT}theme/case/all/detail`, {
        params: params
    }).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//删除
export const themeCaseDelete = params => {
    return axios.get(`${API_ROOT}theme/case/delete/name`, {
        params: params
    }).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//学生进入活动
export const themeStudentDetails = params => {
    return axios.get(`${API_ROOT}theme/student/details`, {
        params: params
    }).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//学生提交变式
export const themeStudentSubmit = params => {
    return axios.post(`${API_ROOT}theme/student/submit`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//学生进入答题总结页
export const themeStudentGetCaseResult = params => {
    return axios.get(`${API_ROOT}theme/student/get/case/result`, {
        params: params
    }).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//学生答题获取知识树
export const themeStudentGetTheme = params => {
    return axios.get(`${API_ROOT}theme/student/get/theme`, {
        params: params
    }).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//预览获取知识树
export const previewGetTheme = params => {
    return axios.get(`${API_ROOT}theme/knowledge/getKnowledgePathById`, {
        params: params
    }).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//老师查看学生基本情况表
export const themeTeacherGetActivityUserPage = params => {
    return axios.post(`${API_ROOT}theme/teacher/getActivityUserPage`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//老师查看学生基本情况表
export const themeTeacherGetActivityCasePage = params => {
    return axios.post(`${API_ROOT}theme/teacher/getActivityCasePage`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//查询指定班级下的活动的专题列表
export const answerListQuestionAnswerTheme = params => {
    return axios.post(`${API_ROOT}question/answer/listQuestionAnswerTheme`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//提出疑问
export const questionAnswerCreateQuestion = params => {
    return axios.post(`${API_ROOT}question/answer/createQuestion`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//回复疑问
export const questionAnswerSaveAnswer = params => {
    return axios.post(`${API_ROOT}question/answer/saveAnswer`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//学生答疑列表
export const questionAnswerGetQuestionAnswerPage = params => {
    return axios.post(`${API_ROOT}question/answer/getQuestionAnswerPage`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//教师答疑列表
export const questionAnswerGetQuestionTitlePage = params => {
    return axios.post(`${API_ROOT}question/answer/getQuestionTitlePage`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//教师端通过id来查询答疑详情信息
export const questionAnswerGetQuestionTitleById = params => {
    return axios.get(`${API_ROOT}question/answer/getQuestionTitleById`, {
        params: params
    }).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//删除答疑答复（答案）
export const questionAnswerRemoveQuestionAnswer = params => {
    return axios.post(`${API_ROOT}question/answer/removeQuestionAnswer`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//删除答疑提出的问题
export const questionAnswerRemoveQuestionTitle = params => {
    return axios.post(`${API_ROOT}question/answer/removeQuestionTitle`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//督促班级下的学生列表
export const classGetUserInfoPageByClass = params => {
    return axios.post(`${API_ROOT}class/getUserInfoPageByClass`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//督促消息列表
export const progressReminderGetProgressReminderPage = params => {
    return axios.post(`${API_ROOT}progress/reminder/getProgressReminderPage`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//发送督促
export const progressReminderCreateProgressReminder = params => {
    return axios.post(`${API_ROOT}progress/reminder/createProgressReminder`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//删除督促
export const progressReminderRemove = params => {
    return axios.post(`${API_ROOT}progress/reminder/remove`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//已读督促
export const progressReminderMarkAsRead = params => {
    return axios.post(`${API_ROOT}progress/reminder/markAsRead`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
}

//课件管理
//新增课件
export const coursewareCreateCourseware = params => {
    return axios.post(`${API_ROOT}courseware/createCourseware`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//列表
export const coursewareGetCoursewarePage = params => {
    return axios.post(`${API_ROOT}courseware/getCoursewarePage`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//删除
export const coursewareRemove = params => {
    return axios.post(`${API_ROOT}courseware/remove`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//编辑
export const coursewareModifyCourseware = params => {
    return axios.post(`${API_ROOT}courseware/modifyCourseware`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//详情
export const coursewareGetCoursewareById = params => {
    return axios.get(`${API_ROOT}courseware/getCoursewareById`, {
        params: params
    }).then(res => res.data).catch(error => {
        console.log(error)
    });
}
//通过科目查专题
export const themeListThemeTitleBySubjectId = params => {
    return axios.get(`${API_ROOT}theme/listThemeTitleBySubjectId`, {
        params: params
    }).then(res => res.data).catch(error => {
        console.log(error)
    });
}




// 修改密码
export const userReset = params => {
    return axios.post(`${API_SSO}user/reset`, params).then(res => res.data).catch(error => {
        console.log(error)
    });
} //修改密码


//拿tokeykey换取token
export const obtainToken = async function (params) {
    return await axios.get(`${API_SSO}token/exchange`, {
        params: params
    }).then(res => res.data).catch(error => {
        console.log(error);
    })
};

//退出登录
export const logout = async function (params) {
    return await axios.post(`${API_SSO}token/logout`, params).then(res => res.data).catch(error => {
        console.log(error);
    })
};

const page = {
    getCase(params) {
        return axios.post(`${API_ROOT}task/getCase`, params)
    }, //根据变式id得到变式的题目
    saveCaseQuestion(params) { //保存所有选择的题目信息并提交
        return axios.post(`${API_ROOT}task/saveCaseQuestion`, params)
    },
    getImmxCase(params) { //得到变式的题目（immx系统）
        return axios.post(`${API_ROOT}task/getCase`, params)
    },
    getIMMXDetail(params) { //得到IMMX展示细节
        return axios.post(`${API_ROOT}task/getIMMXDetail`, params)
    },
    saveUserIMMXAnswer(params) { //保存IMMX答案及查看路径
        return axios.post(`${API_ROOT}task/saveUserIMMXAnswer`, params)
    },
    getUserSpendTime(params) { //活动详情页时长
        return axios.post(`${API_ROOT}task/getUserSpendTime`, params)
    },
    getAllEfficiency(params) { //四象限图
        return axios.post(`${API_ROOT}DataProcess/getAllEfficiency`, params)
    },
    getIMMXTaskStateParameters(params) { //策略三分析效果图
        return axios.post(`${API_ROOT}DataProcess/getIMMXTaskStateParameters`, params)
    },
    getUserImmxPathData(params) { //SPM
        return axios.post(`${API_ROOT}DataProcess/getUserImmxPathData`, params)
    },
    getIMMXCaseRecordData(params) { //table
        return axios.post(`${API_ROOT}DataProcess/getIMMXCaseRecordData`, params)
    },
    getIMMXUserCompleteCase(params) { //下拉IMMX 框
        return axios.post(`${API_ROOT}DataProcess/getIMMXUserCompleteCase`, params)
    },
    getTeacherImmxActiveCase(params) { //K12老师下拉IMMX 框
        return axios.post(`${API_ROOT}DataProcess/getTeacherImmxActiveCase`, params)
    },
}

export default {
    page
}

// 视频材料
// 新增
export const videoAdd = async function (params) {
    return await axios.post(`${API_ROOT}video/add`, params).then(res => res.data).catch(error => {
        console.log(error);
    })
};
// 列表
export const videoList = async function (params) {
    return await axios.post(`${API_ROOT}video/list`, params).then(res => res.data).catch(error => {
        console.log(error);
    })
};
// 删除
export const videoDelete = async function (params) {
    return await axios.post(`${API_ROOT}video/delete`, params).then(res => res.data).catch(error => {
        console.log(error);
    })
};
// 详情
export const videoDetail = async function (params) {
    return await axios.post(`${API_ROOT}video/detail`, params).then(res => res.data).catch(error => {
        console.log(error);
    })
};
// 编辑
export const videoUpdate = async function (params) {
    return await axios.post(`${API_ROOT}video/update`, params).then(res => res.data).catch(error => {
        console.log(error);
    })
};
// 发评论
export const videoCommentAdd = async function (params) {
    return await axios.post(`${API_ROOT}video/comment/add`, params).then(res => res.data).catch(error => {
        console.log(error);
    })
};
// 评论列表
export const videoCommentList = async function (params) {
    return await axios.post(`${API_ROOT}video/comment/list`, params).then(res => res.data).catch(error => {
        console.log(error);
    })
};
// 视频活动浏览完毕
export const videoAccomplish = async function (params) {
    return await axios.post(`${API_ROOT}video/accomplish`, params).then(res => res.data).catch(error => {
        console.log(error);
    })
};


// 添加专题
export const themeAdd = async function (params) {
    return await axios.post(`${API_ROOT}theme/add`, params).then(res => res.data).catch(error => {
        console.log(error);
    })
};
// 专题节点树(备用)
export const themeGetThemeTree = async function (params) {
    return await axios.post(`${API_ROOT}theme/getThemeTree`, params).then(res => res.data).catch(error => {
        console.log(error);
    })
};
// 专题节点树
export const themeGetThemeTreePage = async function (params) {
    return await axios.post(`${API_ROOT}theme/getThemeTreePage`, params).then(res => res.data).catch(error => {
        console.log(error);
    })
};
// 通过主键id查询专题信息
export const themeGetThemeById = async function (params) {
    return await axios.get(`${API_ROOT}theme/getThemeById`, {
        params: params
    }).then(res => res.data).catch(error => {
        console.log(error);
    })
};
// 编辑节点
export const themeModify = async function (params) {
    return await axios.post(`${API_ROOT}theme/modify`, params).then(res => res.data).catch(error => {
        console.log(error);
    })
};
// 删除节点
export const themeDelete = async function (params) {
    return await axios.post(`${API_ROOT}theme/delete?id=${params.id}`, params).then(res => res.data).catch(error => {
        console.log(error);
    })
};
// 发布节点
export const themePublish = async function (params) {
    return await axios.post(`${API_ROOT}theme/publish?id=${params.id}`, params).then(res => res.data).catch(error => {
        console.log(error);
    })
};
// 查询专题描述
export const getThemeDescByThemeId = async function (params) {
    return await axios.get(`${API_ROOT}theme/getThemeDescByThemeId`, {
        params: params
    }).then(res => res.data).catch(error => {
        console.log(error);
    })
};

// 添加知识点
export const knowledgeAdd = async function (params) {
    return await axios.post(`${API_ROOT}theme/knowledge/add`, params).then(res => res.data).catch(error => {
        console.log(error);
    })
};
// 查询知识点
export const getKnowledgeByThemeId = async function (params) {
    return await axios.get(`${API_ROOT}theme/knowledge/getKnowledgeByThemeId`, {
        params: params
    }).then(res => res.data).catch(error => {
        console.log(error);
    })
};
// 根据ID查询知识详情
export const getKnowledgeById = async function (params) {
    return await axios.get(`${API_ROOT}theme/knowledge/getKnowledgeById`, {
        params: params
    }).then(res => res.data).catch(error => {
        console.log(error);
    })
};
// 修改知识点
export const knowledgeModify = async function (params) {
    return await axios.post(`${API_ROOT}theme/knowledge/modify`, params).then(res => res.data).catch(error => {
        console.log(error);
    })
};
// 删除知识点
export const knowledgeDelete = async function (params) {
    return await axios.post(`${API_ROOT}theme/knowledge/delete?id=${params.id}`, params).then(res => res.data).catch(error => {
        console.log(error);
    })
};

// 变式名称分页查询 
export const getThemeCaseNamePage = async function (params) {
    return await axios.post(`${API_ROOT}theme/case/getThemeCaseNamePage`, params).then(res => res.data).catch(error => {
        console.log(error);
    })
};

// 通过班级ID来查询专题树
export const getThemeTreeByClaseId = async function (params) {
    return await axios.get(`${API_ROOT}theme/getThemeTreeByClaseId`, {
        params: params
    }).then(res => res.data).catch(error => {
        console.log(error);
    })
};
// 新建成人活动
export const themeActivityAdd = async function (params) {
    return await axios.post(`${API_ROOT}theme/activity/add`, params).then(res => res.data).catch(error => {
        console.log(error);
    })
};
// 成人活动列表
export const studentGet = async function (params) {
    return await axios.post(`${API_ROOT}theme/student/get`, params).then(res => res.data).catch(error => {
        console.log(error);
    })
};
// 学生进入活动 查询顶部详情
export const topMessage = async function (params) {
    return await axios.get(`${API_ROOT}theme/student/get/top/message`, {
        params: params
    }).then(res => res.data).catch(error => {
        console.log(error);
    })
};
// 活动详情的变式列表
export const studentGetList = async function (params) {
    return await axios.get(`${API_ROOT}theme/student/get/list`, {
        params: params
    }).then(res => res.data).catch(error => {
        console.log(error);
    })
};
// 点击专题加载学生
export const getClassUsers = async function (params) {
    return await axios.get(`${API_ROOT}theme/activity/get/class/users`, {
        params: params
    }).then(res => res.data).catch(error => {
        console.log(error);
    })
};
// 查询指定的知识结点内容
export const getKnowledgeDescById = async function (params) {
    return await axios.get(`${API_ROOT}theme/knowledge/getKnowledgeDescById`, {
        params: params
    }).then(res => res.data).catch(error => {
        console.log(error);
    })
};
// 老师活动列表
export const getActivityPage = async function (params) {
    return await axios.post(`${API_ROOT}theme/teacher/getActivityPage`, params).then(res => res.data).catch(error => {
        console.log(error);
    })
};
// 老师活动列表更改状态
export const toggleActivityEnabled = async function (params) {
    return await axios.post(`${API_ROOT}theme/teacher/toggleActivityEnabled`, params).then(res => res.data).catch(error => {
        console.log(error);
    })
};
// export const fetch = (url, params) => {
//     return axios.post(url, params).then(res => res.data).catch((error) => {
//         console.log(error);
//     })
// }