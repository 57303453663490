export default {
  //年级
  grade: function (s) {
    let grade = [{
      label: '一年级',
      value: 1
    }, {
      label: '二年级',
      value: 2
    }, {
      label: '三年级',
      value: 3
    }, {
      label: '四年级',
      value: 4
    }, {
      label: '五年级',
      value: 5
    }, {
      label: '六年级',
      value: 6
    }, {
      label: '初中一年级',
      value: 7
    }, {
      label: '初中二年级',
      value: 8
    }, {
      label: '初中三年级',
      value: 9
    }, {
      label: '高中一年级',
      value: 10
    }, {
      label: '高中二年级',
      value: 11
    }, {
      label: '高中三年级',
      value: 12
    }]
    return grade
  },
  //学科
  subject: function () {
    let subject = [{
      value: 1,
      label: '数学'
    }, {
      value: 2,
      label: '语文'
    }, {
      value: 3,
      label: '英语'
    }]
    return subject
  },
  // 题目类型
  variantSubjectData: function () {
    return [{
        value: 1,
        label: "填空题",
      },
      {
        value: 2,
        label: "单选题",
      },
      {
        value: 3,
        label: "多选题",
      },
      {
        value: 4,
        label: "判断题",
      },
      {
        value: 5,
        label: "简答题",
      },
    ]
  },
  // K12题目标题
  descriptionType: function () {
    return { //题目标题
      1: "掌握要求",
      2: "考试要求",
      3: "知识点名称",
      4: "重要知识",
      5: "常见错误",
      6: "题目内容",
      7: "题目特点",
      8: "难以程度",
      9: "适用对象",
    }
  }
}