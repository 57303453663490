export default {
    recordTree: function (name, time, click, state) {
        var timestamp = Date.parse(new Date());
        var remaintime = timestamp - time
        // console.log(timestamp); // 1622427159000
        var obj = {}
        if (state == 1) {
            obj = {
                startSecond: timestamp / 1000,
                startName: click + name,
            }
        } else {
            obj = {
                time: remaintime,
                endSecond: timestamp / 1000,
                endName: click + name,
            }
        }
        return obj
    }
}