<!-- 计时器 -->
<template>
  <div class="goInfo">
    <ul>
      <li>{{ one }}</li>
      <span>:</span>
      <li>{{ two }}</li>
      <span>:</span>
      <li>{{ three }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
  data () {
    return {
      flag: null,
      one: "00", // 时
      two: "00", // 分
      three: "00", // 秒
      abc: 0, // 秒的计数
      cde: 0, // 分的计数
      efg: 0, // 时的计数
    };
  },
  props: {
    msg: String,
    totalTime: Number,
  },
  watch: {
    totalTime (value) {
      this.time(value);
    },
  },
  mounted () {
  },
  created () {
    this.time();
  },
  methods: {
    //计时
    Settime () {
      let set = setInterval(() => {
        this.count++;
        this.time =
          (Math.floor(this.count / 60) > 9
            ? Math.floor(this.count / 60)
            : "0" + Math.floor(this.count / 60)) +
          ":" +
          (this.count % 60 > 9 ? this.count % 60 : "0" + (this.count % 60));
      }, 1000);
    },
    time (value) {
      if (value) {
        let i = value;
        this.three = i;
        let a = Math.floor(i / 60);
        if (a <= 9) {
          this.two = "0" + a;
        } else {
          this.two = a;
        }
        this.three = i % 60 > 9 ? i % 60 : "0" + (i % 60);
      }
    },
  },
};
</script>
<style lang='less' scoped>
@import '../../style/timer/timer.less';
@import '../../style/mouse.less';
</style>
