export default {
  identity: function (functionName) {
    // 路由地址的租户id
    var urlAppId = window.location.href.split('=')[2].split('#')[0]
    // 本地储存租户的信息
    let appIds = JSON.parse(localStorage.getItem('appIds'))
    // 如果存在
    if (appIds) {
      // 找到当前租户
      let goal_appIds = appIds.find(item => {
        return item.appId == urlAppId
      })
      if (goal_appIds) {
        let appIdMenu = JSON.parse(goal_appIds.menu) //转化menu
        // 默认false
        let appIdMenu_boole = false
        appIdMenu.forEach(item => {
          let appIdMenu_f = null
          // 寻找目标权限
          appIdMenu_f = item.functionList.find(conform => {
            return conform.functionName == functionName
          })
          // 如果存在,置true
          if (appIdMenu_f) {
            appIdMenu_boole = true
          }
        })
        return appIdMenu_boole
      }

    }
  },
  userId: function (params) {
    var urlAppId = window.location.href.split("=")[2].split("#")[0];
    let appIds = JSON.parse(localStorage.getItem("appIds"));
    if (appIds) {
      let target = appIds.find(item => {
        return item.appId === urlAppId
      })
      return target.userId;
    }
  },
}