import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import fence from '../src/directive/fence'
import snap from '../src/directive/snap'
import 'element-ui/lib/theme-chalk/index.css';
import api from "./api/api";
import utils from './util/identity.js'
import duration from './util/duration.js'
import currentTime from './util/currentTime.js'
import appId_title from './util/appId_title.js'
import parameter from './util/parameter.js'
import unique from './util/unique.js'
import recordOnOption from './util/recordOnOption.js'
import recordOnTree from './util/recordOnTree.js'
import {
  judgeOdevity,
  letter
} from './util/judgeOdevity.js'
import JsonExcel from 'vue-json-excel'
import iView from 'iview';
import 'iview/dist/styles/iview.css';

Vue.use(iView);

require('./mock');

// 引入打印和导出pdf
import vueToPdf from 'vue-to-pdf';
import VueEasyPrinter from 'vue-easy-printer';
Vue.use(vueToPdf);
Vue.use(VueEasyPrinter);



//引入富文本
import Tinymce from 'tinymce';

Vue.prototype.$tinymce = Tinymce;

var _hmt = _hmt || [];
window._hmt = _hmt; // 修改为window 全局变量
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?8c31443f411d7c391c165ef110df9c35";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();

import flag from './router/leaveConfirm.js'
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts;
Vue.prototype.utils = utils; //全局权限解析
Vue.prototype.duration = duration; //全局时长计算
Vue.prototype.currentTime = currentTime; //全局计算当前日期与时间戳
Vue.prototype.appId_title = appId_title;
Vue.prototype.parameter = parameter; //全局公共参数
Vue.prototype.unique = unique; //全局数组对象去重
Vue.prototype.judgeOdevity = judgeOdevity; //全局2的次方计算
Vue.prototype.letter = letter; //全局计算字母位置
Vue.prototype.recordOption = recordOnOption; //记录当时选项
Vue.prototype.recordTree = recordOnTree; //记录当前树


Vue.use(ElementUI);
Vue.component('downloadExcel', JsonExcel)
Vue.prototype.$api = api
Vue.prototype.$store = store;





Vue.config.productionTip = false

new Vue({
  router,
  store,
  fence,
  snap,
  data: {
    Bus: new Vue()
  },
  render: h => h(App)
}).$mount('#app')