var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"scroll-left"},[_c('el-scrollbar',{staticStyle:{"height":"100%"}},[_c('el-timeline',_vm._l((_vm.taskInformation),function(item,index){return _c('el-timeline-item',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.$route.path == '/activity'
            ? _vm.completionStatus == 'all' || _vm.completionStatus == item.showMsg
            : _vm.completionStatus == undefined
        ),expression:"\n          $route.path == '/activity'\n            ? completionStatus == 'all' || completionStatus == item.showMsg\n            : completionStatus == undefined\n        "}],key:index,attrs:{"id":item.current ? 'scroll' : '',"hide-timestamp":_vm.$route.path !== '/activity',"timestamp":item.endTime
            ? item.startTime + ' — ' + item.endTime
            : item.startTime + ' — 至今',"color":_vm.$route.path !== '/activity' ? '#fff' : '#409EFF',"placement":"top"}},[(_vm.$route.path == '/activity')?_c('div',{staticClass:"activity-button"},[(item.show_edit)?_c('el-button',{directives:[{name:"fence",rawName:"v-fence",value:({ data: '', functions: ['编辑活动'] }),expression:"{ data: '', functions: ['编辑活动'] }"}],attrs:{"type":"primary","icon":"el-icon-edit"},on:{"click":function($event){return _vm.editTask(item)}}}):_vm._e(),_c('el-button',{directives:[{name:"fence",rawName:"v-fence",value:({ data: '', functions: ['删除活动'] }),expression:"{ data: '', functions: ['删除活动'] }"}],attrs:{"type":"primary","icon":"el-icon-delete"},on:{"click":function($event){return _vm.activityDelete(item.id)}}})],1):_vm._e(),_c('span',{on:{"click":function($event){return _vm.goActivity(item, item.id)}}},[_c('el-card',{class:_vm.styleClass(item),attrs:{"shadow":"hover"}},[_c('span',{staticClass:"card-text"},[_vm._v(_vm._s(item.title))]),(_vm.$route.path == '/activity')?_c('span',{staticClass:"span-type"},[_vm._v(_vm._s(item.typeMsg))]):_vm._e(),_c('span',{staticClass:"subtitle"},[_vm._v(_vm._s(item.stemName))]),_c('div',{staticClass:"completion-rate"},[(item.stemType !== 2 && _vm.$route.path == '/activity')?_c('p',[_vm._v("完成率：")]):_vm._e(),(item.stemType !== 2 && _vm.$route.path == '/activity')?_c('el-progress',{attrs:{"percentage":item.completion ? Number(item.completion) : 0}}):_vm._e(),(item.totalNumber)?_c('span',[_vm._v("总人数："+_vm._s(item.totalNumber))]):_vm._e(),(item.totalNumber)?_c('span',[_vm._v("完成人数："+_vm._s(item.users))]):_vm._e(),_c('div',[(_vm.$route.path !== '/activityDetail' && item.stemType == 2)?_c('p',{directives:[{name:"fence",rawName:"v-fence",value:({ data: '', functions: ['个人完成率'] }),expression:"{ data: '', functions: ['个人完成率'] }"}]},[_vm._v("个人完成率：")]):_vm._e(),(
                    _vm.$route.path !== '/activityDetail' && item.stemType == 2
                  )?_c('el-progress',{directives:[{name:"fence",rawName:"v-fence",value:({ data: '', functions: ['个人完成率'] }),expression:"{ data: '', functions: ['个人完成率'] }"}],attrs:{"percentage":item.completion ? Number(item.completion) : 0}}):_vm._e()],1),(_vm.$route.path !== '/activityDetail' && item.stemType == 2)?_c('p',{directives:[{name:"fence",rawName:"v-fence",value:({ data: '', functions: ['总体完成率'] }),expression:"{ data: '', functions: ['总体完成率'] }"}]},[_vm._v("总体完成率：")]):_vm._e(),(_vm.$route.path !== '/activityDetail' && item.stemType == 2)?_c('el-progress',{directives:[{name:"fence",rawName:"v-fence",value:({ data: '', functions: ['总体完成率'] }),expression:"{ data: '', functions: ['总体完成率'] }"}],attrs:{"percentage":item.totalityCompletion
                    ? Number(item.totalityCompletion)
                    : 0}}):_vm._e(),(_vm.$route.path !== '/activity')?_c('p',[_vm._v(_vm._s(item.state || "未完成"))]):_vm._e(),(!_vm.is_submit)?_c('span',{staticClass:"difficulty",staticStyle:{"top":"0"}},[_vm._v(" 难度系数： "),_c('el-rate',{attrs:{"disabled":""},model:{value:(item.difficulty),callback:function ($$v) {_vm.$set(item, "difficulty", $$v)},expression:"item.difficulty"}})],1):_vm._e(),(_vm.$route.path == '/activity')?_c('span',{directives:[{name:"fence",rawName:"v-fence",value:({ data: '', functions: ['创建活动'] }),expression:"{ data: '', functions: ['创建活动'] }"}],staticClass:"complete-number"},[_vm._v("已有"+_vm._s(item.completeUser)+"名同学完成该活动")]):_vm._e()],1)])],1)])}),1)],1),_c('el-dialog',{attrs:{"title":"提示","visible":_vm.dialogVisible,"width":"30%"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('span',[_vm._v("教学活动未开启或者已被终止")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }