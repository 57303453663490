<!-- 头部 -->
<template >
  <el-header>
    <div style="display: flex; height: 60px; line-height: 60px">
      <span class="span-icon">
        <i
          :class="collapsed ? 'el-icon-s-fold' : 'el-icon-s-unfold'"
          @click="toggle(collapsed)"
        ></i>
      </span>
      <span class="span-jiaoyu"></span>
      <!-- <div class="header-text">教师命题能力培训系统</div> -->
      <!-- <div class="header-text">学习管理系统</div> -->
      <div class="header-text">公路水运工程试验检测资格考试培训系统</div>
      <!-- <div class="header-text">{{title}}</div> -->
    </div>

    <div class="classroom-right">
      <el-badge
        :is-dot="showBadge"
        class="item"
        v-if="utils.identity('站内信')"
      >
        <span class="span-rigth-icon" @click="goMail">
          <i class="el-icon-chat-dot-round"></i>
        </span>
      </el-badge>

      <el-badge
        :is-dot="showBadge"
        class="item"
        v-fence="{ data: '', functions: ['督促'] }"
      >
        <span class="span-rigth-icon" @click="goMail(1)">
          <i class="el-icon-bell"></i>
        </span>
      </el-badge>

      <div class="user">
        <el-avatar
          :size="30"
          src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
        ></el-avatar>
        <el-dropdown>
          <span class="el-dropdown-link" v-html="message">
            {{ message }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="personalCenter"
              >个人中心</el-dropdown-item
            >
            <el-dropdown-item @click.native="changePassword"
              >修改密码</el-dropdown-item
            >
            <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <!--修改密码-->
    <el-dialog
      class="plusProModel"
      id="passwordDialog"
      title="修改密码"
      :visible.sync="passwordDialog"
      width="746px"
      :before-close="cancelChange"
      top="23vh"
    >
      <el-form
        :model="passwordForm"
        status-icon
        :rules="pwdRules"
        ref="passwordForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="原密码" size prop="oldPassword">
          <el-input
            size="mini"
            placeholder="请输入原密码"
            type="password"
            v-model="passwordForm.oldPassword"
            auto-complete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input
            size="mini"
            placeholder="请输入以字母开头，由字母数字组成的6-12位有效密码"
            type="password"
            v-model="passwordForm.newPassword"
            auto-complete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" prop="surePassword">
          <el-input
            size="mini"
            placeholder="请再次输入密码"
            type="password"
            v-model="passwordForm.surePassword"
            auto-complete="off"
          ></el-input>
        </el-form-item>
        <div size="mini" class="btn">
          <el-button @click="cancelChange">取消</el-button>
          <el-button type="primary" @click="sureChange('passwordForm')"
            >确定</el-button
          >
        </div>
      </el-form>
    </el-dialog>
  </el-header>
</template>

<script>
import {
  logout,
  userReset,
  messageList,
  progressReminderGetProgressReminderPage,
} from "../api/api";
import md5 from "js-md5";
import mqtt from "mqtt";
import { setISmqtt, setIsBadge } from "vuex";
export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请输入密码"));
      } else if (!/^[a-zA-Z][a-zA-Z0-9]{5,11}$/.test(value)) {
        callback(new Error("请输入以字母开头，由字母数字组成的6-12位有效密码"));
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.passwordForm.newPassword) {
        callback(new Error("两次输入密码不一致"));
      } else if (!/^[a-zA-Z][a-zA-Z0-9]{5,11}$/.test(value)) {
        callback(new Error("请输入以字母开头，由字母数字组成的6-12位有效密码"));
      } else {
        callback();
      }
    };
    return {
      msg: "",
      mtopic: null, //站内信主题
      client: {}, //mqtt
      message: null,
      collapsed: true,
      appId: null,
      token: null,
      passwordDialog: false,
      passwordForm: {
        userId: null,
        oldPassword: null,
        newPassword: null,
        surePassword: null,
      },
      pwdRules: {
        oldPassword: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          {
            pattern: /^[a-zA-Z][a-zA-Z0-9]{5,11}$/,
            message: "请输入以字母开头，由字母数字组成的6-12位有效密码",
            trigger: "blur",
          },
        ],
        newPassword: [
          {
            required: true,
            message: "请输入新密码",
            trigger: "blur",
          },
          {
            validator: validatePass,
            trigger: "blur",
          },
        ],
        surePassword: [
          {
            required: true,
            message: "请输入确认密码",
            trigger: "blur",
          },
          {
            validator: validatePass2,
            trigger: "blur",
          },
        ],
      },
      appIdUser: null, //用户id
      showBadge: false,
      tenantId: null, //租户id
      clientId: null, //客户端id
      firstPlayFlag: true, // 第一次播放标记
    };
  },
  watch: {
    $route(to, from) {
      if (from.path == "/educationMail") {
        messageList({}).then((res) => {
          if (res.data) {
            res.data.list.map((item) => {
              item.time = this.rTime(item.createDate).split(" ")[0].split("-");
              item.time1 = this.rTime(item.createDate).split(" ")[1];
              item.time = item.time[1] + "-" + item.time[2];
              item.time1 = item.time + " " + item.time1;
            });
            for (let i = 0; i < res.data.list.length; i++) {
              if (res.data.list[i].state == 0) {
                this.showBadge = true;
                break;
              } else {
                this.$store.commit("setIsBadge1");
                this.showBadge = false;
              }
            }
          }
        });
      }
      if (from.path == "/urge") {
        var obj = {
          query: {},
        };
        progressReminderGetProgressReminderPage(obj).then((res) => {
          for (let i = 0; i < res.data.list.length; i++) {
            if (res.data.list[i].readState == 0) {
              //小红点添加
              this.showBadge = true;
              this.$store.commit("setIsBadge");
              this.$notify({
                title: "成功",
                message: "您有新邮件",
                type: "success",
              });
              break;
            } else {
              this.$store.commit("setIsBadge1");
              this.showBadge = false;
            }
          }
        });
      }
    },
  },
  mounted() {
    this.analysis(); //解析
    //站内信弹窗
    this.MQTT();
    // this.load_title()
  },
  componented: {},
  methods: {
    //vuex
    setISmqtt() {
      this.$store.commit("setISmqtt");
    },
    //MQTT
    MQTT() {
      if (this.$store.state.ISmqtt) {
        //只获取一次
        this.setISmqtt();
        //连接
        this.client = mqtt.connect("ws://39.103.222.176:8083/mqtt", {
          username: this.mtopic,
          // cleanSession:false,
          keepAlive: 60,
          connectTimeout: 4000,
          clientId: "mqttjs_" + Math.random().toString(16).substr(2, 8),
        });
        //连接成功
        this.client.on("connect", (e) => {
          this.client.subscribe(this.mtopic, (err) => {
            if (!err) {
              // this.client.publish(this.mtopic, "你好啊");
            }
            // this.loadMessage(); //调用接口来新消息
          });
        });
        this.client.on("message", (topic, message) => {
          this.msg = message; //来的内容
          this.loadMessage(); //调用接口来新消息
        });
      }
      if (this.$store.state.IsBadge) {
        this.showBadge = true;
      }
      //连接失败
      // this.client.onC
    },
    // 信息列表
    loadMessage() {
      if (this.utils.identity("督促")) {
        console.log("刚来就调用获取督促列表");
        var obj = {
          query: {},
        };
        progressReminderGetProgressReminderPage(obj).then((res) => {
          console.log(res.data.list);

          for (let i = 0; i < res.data.list.length; i++) {
            if (res.data.list[i].readState == 0) {
              //小红点添加
              this.showBadge = true;
              this.$store.commit("setIsBadge");
              this.$notify({
                title: "成功",
                message: "您有新邮件",
                type: "success",
              });
              break;
            } else {
              this.$store.commit("setIsBadge1");
              this.showBadge = false;
            }
          }
        });
      } else {
        messageList({}).then((res) => {
          if (res.data) {
            res.data.list.map((item) => {
              item.time = this.rTime(item.createDate).split(" ")[0].split("-");
              item.time1 = this.rTime(item.createDate).split(" ")[1];
              item.time = item.time[1] + "-" + item.time[2];
              item.time1 = item.time + " " + item.time1;
            });
            for (let i = 0; i < res.data.list.length; i++) {
              if (res.data.list[i].state == 0) {
                //小红点添加
                this.showBadge = true;
                this.$store.commit("setIsBadge");
                this.$notify({
                  title: "成功",
                  message: "您有新邮件",
                  type: "success",
                });
                break;
              } else {
                this.$store.commit("setIsBadge1");
                this.showBadge = false;
              }
            }
          }
        });
      }
    },
    // 修改密码
    changePassword() {
      this.passwordDialog = true;
    },
    sureChange(formName) {
      /**
       * @description 修改密码
       * @param {Number} userId
       * @param {String} appId 应用ID
       * @param {String} oldPassword 原密码
       * @param {String} newPassword 新密码
       * @param {String} rePassword 确认密码
       * @param {Number} flag
       */
      let self = this;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            userId: this.appIdUser,
            appId: "",
            oldPassword: md5(self.passwordForm.oldPassword),
            newPassword: md5(self.passwordForm.newPassword),
            rePassword: md5(self.passwordForm.surePassword),
            flag: 1,
          };
          userReset(params).then((res) => {
            if (res.err_CODE == 0) {
              this.$message({
                message: res.err_MESSAGE,
                type: "success",
              });
              window.location.reload();
            } else {
              this.$message.error(res.err_MESSAGE);
            }
          });
        } else {
          return false;
        }
      });
    },
    cancelChange() {
      /**
       * @description 修改密码 弹出框取消
       */
      this.passwordDialog = false;
      this.$refs["passwordForm"].resetFields();
    },
    // 解析appIds
    getQueryVariable(variable)
{
	variable = variable.toLowerCase();
    var query = window.location.search.substring(1).toLowerCase();
	var vars = query.split("&");
    for (var i=0;i<vars.length;i++) {
		var pair = vars[i].split("=");
		if(pair[0] == variable){return pair[1];}
	}
	return(false);

},
    analysis() {
      var urlAppId = this.getQueryVariable("appid");
      let appIds = JSON.parse(localStorage.getItem("appIds"));
      if (appIds) {
        appIds.forEach((item) => {
          if (item.appId === urlAppId) {
            this.appIdUser = item.userId;
            this.message = item.fullName;
            this.tenantId = item.tenantId;
            this.mtopic = "TOPIC/" + this.tenantId + "/" + this.appIdUser;
          }
        });
      }
    },
    // 跳转站内信
    goMail(val) {
      if (val == 1) {
        this.$router.push({
          path: "/urge",
        });
        return;
      }
      this.$router.push("/educationMail");
    },
    // 切换显示
    toggle(showtype) {
      this.collapsed = !showtype;
      this.$root.Bus.$emit("toggle", this.collapsed);
      this.$store.commit("setCollapse", this.collapsed);
    },
    logout() {
       var urlAppId = this.getQueryVariable("appid");
      let appIds = JSON.parse(localStorage.getItem("appIds"));
      if (appIds) {
        appIds.map((item) => {
          if (item.appId == urlAppId) {
            this.appId = item.appId;
            this.token = item.token;
          }
        });
      }
      let param = {
        appId: this.appId,
        token: this.token,
      };
      logout(param).then((res) => {
        if (res.err_CODE == 0) {
          appIds.map((item, index) => {
            if (item.appId == urlAppId) {
              appIds.splice(index, 1);
              localStorage.setItem("appIds", JSON.stringify(appIds));
            }
          });
          localStorage.removeItem("toPath");
          localStorage.removeItem("toName");
          location.reload();
        }
      });
    },
    // 个人中心
    personalCenter() {
      this.$router.push("/personalCenter");
    },
    // 时间转换
    rTime(date) {
      var json_date = new Date(date).toJSON();
      return new Date(new Date(json_date) + 8 * 3600 * 1000)
        .toISOString()
        .replace(/T/g, " ")
        .replace(/\.[\d]{3}Z/, "");
    },
  },
};
</script>
<style lang='less' scoped>
@import "../style/mouse.less";
// 头部公共
.el-header {
  width: 100%;
  height: 44px;
  background: #ffffff;
  padding: 0;
  box-shadow: 0px 2px 4px 0px rgba(151, 200, 250, 0.5);
  position: relative;

  .header-text {
    display: inline-block;
    height: 100%;
    // height: 30px;
    font-size: 16px;
    font-weight: 700;
    color: #1f1f1f;
    position: relative;
    left: 100px;
    // line-height: 30px;
    // position: absolute;
    // top: 50%;
    // // left: 180px;
    // // left: 140px;
    // left: 240px;
    // transform: translate(-50%, -50%);
  }

  .span-icon {
    display: inline-block;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translate(-50%, -50%);
    color: #d3dce6;
    cursor: pointer;
  }

  .span-jiaoyu {
    display: inline-block;
    width: 22px;
    height: 18px;
    background-image: url(../assets/img/jiaoyu@2x.png);
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 28px;
    left: 75px;
    transform: translate(-50%, -50%);
  }

  .classroom-right {
    width: 150px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;

    .span-rigth-icon {
      color: #409eff;
      font-size: 20px;
    }

    .user {
      width: 110px;
      height: 30px;
      padding-left: 15px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
      border-left: 1px solid #dfdfdf;

      .el-dropdown {
        // position: absolute;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
  }
}
.el-dropdown-menu__item {
  width: 100px;
}
.el-dropdown-link {
  width: 60px;
  display: inline-block;
  overflow: hidden; //多出部分隐藏
  white-space: nowrap; //一行显示
  text-overflow: ellipsis; //是否显示省略号
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.personal {
  span {
    display: block;
  }
}
.plusProModel {
  position: relative;
  /deep/.el-form-item__label {
    text-align: left;
  }
  .btn {
    display: inline-block;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
  }
}
.item {
  margin-top: 10px;
  margin-right: 85px;
  position: absolute;
  top: 24px;
  transform: translate(0, -50%);
}
</style>
