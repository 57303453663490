<!-- 公共头部--侧边栏 -->
<template>
  <el-container class="mainPage">
    <!-- 头部导航栏 -->
    <el-header>
      <header-cmp></header-cmp>
    </el-header>
    <el-container style="height: 80%;">
      <!-- 侧边导航栏 -->
      <aside-cmp class="asideCmp"></aside-cmp>
      <!-- 内容 -->
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import headerCmp from '../components/header.vue'
import asideCmp from "../components/leftNav.vue"
export default {
  data () {
    return {

    }
  },
  components: {
    headerCmp,
    asideCmp
  },
  methods: {}
}
</script>
<style lang='less' scoped>
@import '../style/index.less';
</style>
