export default {
  // 函数传入任意值则返回日期+时间, 不传值则返回日期
  currentTime: function (val) {
    var date = new Date();
    var seperator1 = "-";
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    if (month >= 1 && month <= 9) {
      month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
      strDate = "0" + strDate;
    }
    let hh = new Date().getHours();
    let mf =
      new Date().getMinutes() < 10 ?
      "0" + new Date().getMinutes() :
      new Date().getMinutes();
    let ss =
      new Date().getSeconds() < 10 ?
      "0" + new Date().getSeconds() :
      new Date().getSeconds();
    if (!val) {
      var currentdate = year + seperator1 + month + seperator1 + strDate;
    } else {
      var currentdate = year + seperator1 + month + seperator1 + strDate + " " + hh + ":" + mf + ":" + ss;
    }

    return currentdate;
  },
  getTimestamp(time) { //把时间日期转成时间戳
    return (new Date(time)).getTime() / 1000
  },
  formatDate(value) { //时间戳转成日期
    let date = new Date(value);
    let y = date.getFullYear();
    let MM = date.getMonth() + 1;
    MM = MM < 10 ? "0" + MM : MM;
    let d = date.getDate();
    d = d < 10 ? "0" + d : d;
    let h = date.getHours();
    h = h < 10 ? "0" + h : h;
    let m = date.getMinutes();
    m = m < 10 ? "0" + m : m;
    let s = date.getSeconds();
    s = s < 10 ? "0" + s : s;
    return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
  }
}