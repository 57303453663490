import router from './index'
import Vue from 'vue'
import store from '../store/index'
let BrowserRouterData = ['/materialGather/particulars', '/materialGather/titleList/edit', '/materialGather/newExaminationPaper', '/materialGather/TestAnswer'] //要拦截的浏览器的路径
router.beforeEach((to, from, next) => {
    BrowserRouterData.map((item, index) => {
        if (to.path == item) { // 当进入这个页面进行记录  
            store.commit('setBrowserRouter', true)
            next();
            return
        } else {
            if (store.state.BrowserRouter) { // 如果路由发生变化判断信号
                if (from.path == item) {
                    Vue.prototype.$confirm('您确定要退出此页面?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        store.commit("setBrowserRouter", false);
                        next()
                    })
                }
            } else {
                next()
            }
        }
    })

});


// export default flag