<!-- 循环活动列表和活动里的列表 -->
<template>
  <div class="scroll-left">
    <el-scrollbar style="height: 100%">
      <el-timeline>
        <el-timeline-item
          :id="item.current ? 'scroll' : ''"
          v-show="
            $route.path == '/activity'
              ? completionStatus == 'all' || completionStatus == item.showMsg
              : completionStatus == undefined
          "
          :hide-timestamp="$route.path !== '/activity'"
          :timestamp="
            item.endTime
              ? item.startTime + ' — ' + item.endTime
              : item.startTime + ' — 至今'
          "
          :color="$route.path !== '/activity' ? '#fff' : '#409EFF'"
          placement="top"
          v-for="(item, index) in taskInformation"
          :key="index"
        >
          <div class="activity-button" v-if="$route.path == '/activity'">
            <el-button
              type="primary"
              icon="el-icon-edit"
              @click="editTask(item)"
              v-fence="{ data: '', functions: ['编辑活动'] }"
              v-if="item.show_edit"
            ></el-button>
            <el-button
              type="primary"
              @click="activityDelete(item.id)"
              icon="el-icon-delete"
              v-fence="{ data: '', functions: ['删除活动'] }"
            ></el-button>
          </div>
          <span @click="goActivity(item, item.id)">
            <el-card shadow="hover" :class="styleClass(item)">
              <span class="card-text">{{ item.title }}</span>
              <span class="span-type" v-if="$route.path == '/activity'">{{item.typeMsg}}</span>
              <span class="subtitle">{{ item.stemName }}</span>
              <div class="completion-rate">
                <p v-if="item.stemType !== 2 && $route.path == '/activity'">完成率：</p>
                <el-progress
                  v-if="item.stemType !== 2 && $route.path == '/activity'"
                  :percentage="item.completion ? Number(item.completion) : 0"
                ></el-progress>
                <span v-if="item.totalNumber">总人数：{{ item.totalNumber }}</span>
                <span v-if="item.totalNumber">完成人数：{{ item.users }}</span>
                <div>
                  <p
                    v-fence="{ data: '', functions: ['个人完成率'] }"
                    v-if="$route.path !== '/activityDetail' && item.stemType == 2"
                  >个人完成率：</p>
                  <el-progress
                    v-fence="{ data: '', functions: ['个人完成率'] }"
                    v-if="
                      $route.path !== '/activityDetail' && item.stemType == 2
                    "
                    :percentage="item.completion ? Number(item.completion) : 0"
                  ></el-progress>
                </div>
                <p
                  v-fence="{ data: '', functions: ['总体完成率'] }"
                  v-if="$route.path !== '/activityDetail' && item.stemType == 2"
                >总体完成率：</p>
                <el-progress
                  v-fence="{ data: '', functions: ['总体完成率'] }"
                  v-if="$route.path !== '/activityDetail' && item.stemType == 2"
                  :percentage="
                    item.totalityCompletion
                      ? Number(item.totalityCompletion)
                      : 0
                  "
                ></el-progress>
                <p v-if="$route.path !== '/activity'">{{ item.state || "未完成" }}</p>
                <span v-if="!is_submit" class="difficulty" style="top: 0">
                  难度系数：
                  <el-rate v-model="item.difficulty" disabled></el-rate>
                </span>
                <span
                  v-fence="{ data: '', functions: ['创建活动'] }"
                  v-if="$route.path == '/activity'"
                  class="complete-number"
                >已有{{ item.completeUser }}名同学完成该活动</span>
              </div>
            </el-card>
          </span>
        </el-timeline-item>
      </el-timeline>
    </el-scrollbar>
    <!-- 弹窗 -->
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <span>教学活动未开启或者已被终止</span>
    </el-dialog>
  </div>
</template>

<script>
import { activeDelete } from "../../api/api";
import { activeList } from "../../api/api";
export default {
  props: ["completionStatus", "activityList", "knowledge"],
  data () {
    return {
      taskInformation: [], //活动列表
      dialogVisible: false, //控制教学活动未开启的弹框是否显示
      showCompletion: true, //控制进度条是否显示
      is_submit: null, //是否显示难度
      status: 0, //0  老师  1  学生
      labeladmin: 0,
    };
  },
  watch: {
    // 监听活动列表（该数据是活动列表页传进来的）
    activityList (val, old) {
      if (val !== old) {
        this.taskInformation = val;
        let date = this.currentTime.currentTime();
        this.taskInformation.map((item) => {
          item["show_edit"] = true;
          if (date > item.endTime && item.endTime !== null) {
            item["show_edit"] = false;
          }
          if (item.type == 0) {
          } else if (item.type == 1) {
            item["typeMsg"] = "PK";
          } else if (item.type == 2) {
            item["typeMsg"] = "帮扶";
          }
          if (item.completion == 100) {
            item["showMsg"] = "已完成";
          } else {
            item["showMsg"] = "未完成";
          }
          // 标签教务管理的完成率
          let totalityCompletion = item.completeUser / item.allUser;
          item["totalityCompletion"] = parseInt(totalityCompletion * 100);
          if (item.totalityCompletion >= 100) {
            item.completion = 100;
          }
        });
      }
    },
    // 监听详情页数据（该数据是活动详情列表页的数据）
    knowledge (val, old) {
      if (val !== old) {
        this.taskInformation = val.stems;
        this.taskInformation.map((item) => {
          if (item.isComplete == 1) {
            if (this.is_submit) {
              item["state"] = "已完成";
            }
            item.completion = 100;
          } else {
            if (this.is_submit) {
              item["state"] = "未完成";
            }
          }
        });
      }
    },
  },
  mounted () {
    // 活动列表滚动
    // setTimeout(() => {
    //   var anchor = document.querySelector("#scroll");
    //   if (anchor) {
    //     anchor.scrollIntoView({ behavior: "smooth", block: "start" });
    //   }
    // }, 500);
    this.analysis();
    if (
      this.utils.identity("个人完成率") ||
      this.$route.path == "/activityDetail"
    ) {
      this.showCompletion = false;
    }
  },
  methods: {
    //样式
    styleClass (item) {
      if (this.status == 0 && this.$route.path == "/activityDetail") {
        item["totalNumber"] = this.knowledge.users.length;
        item.completion = Number(
          ((item.users / item.totalNumber) * 100).toFixed(2)
        );
        if (item.completion >= 100) {
          item.state = "已完成";
        }
      }
      if (this.$route.path == "/activity") {
        if (item.isStart == 0 || !item.isOpen) {
          //isStart 0未开始  1开始
          return "card-task-gray";
        }
        if (item.existence) {
          return "card-task-urgent";
        } else if (item.completion !== 100) {
          return "card-task-blue";
        } else {
          if (item.isStart == 0 || !item.isOpen) {
            //isStart 0未开始  1开始
            return "card-task-gray";
          }
          return "card-task-green";
        }
      } else if (item.completion !== 100) {
        if (item.state == "已完成") {
          return "card-task-green";
        }
        return "card-task-blue";
      } else {
        if (item.state == "两次都错") {
          return "card-task";
        }
        if (item.state == "一次正确") {
          return "card-task-green";
        }
        if (item.state == "第一次错误，第二次正确") {
          return "card-task-YellowGreen";
        }
        return "card-task-green";
      }
    },
    // 解析appIds
    analysis () {
      if (this.utils.identity("K12做题") || this.utils.identity("IMX做题")) {
        this.status = 1;
      }
      if (this.utils.identity("提交答案")) {
        this.is_submit = "提交答案";
      }
      if (this.utils.identity("提交参考答案")) {
        this.is_submit = "提交参考答案";
      }
    },
    // 编辑
    editTask (val) {
      this.$emit("editTask", val);
    },
    // 删除
    activityDelete (val) {
      this.$confirm("此操作将永久删除该活动, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let param = {
            id: val,
          };
          activeDelete(param).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: "删除成功！",
                type: "success",
              });
              this.$emit("del_Activity", this.$route.query.classId);
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //对比
    goActivity (item, data) {
      if (
        this.$route.query.stemType != 0 &&
        this.$route.path == "/activityDetail"
      ) {
        return;
      }
      let date = this.currentTime.currentTime();
      // 判断是不是紧急
      // 是老师或者是开启状态并且是当前时间大于等于开启时间则放行
      if (
        this.status == 0 ||
        (item.isStart == 1 && date >= item.startTime[0])
      ) {
        if (this.$route.path == "/activity") {
          this.$router.push({
            path: "/activityDetail",
            query: { id: data, stemType: item.stemType, classId: item.classId },
          });
        }
      } else {
        if (this.$route.path == "/activity") {
          this.dialogVisible = true;
        }
      }
      if (this.$route.path == "/activityDetail" && this.is_submit) {
        let unfinished = null;
        if (this.knowledge) {
          unfinished = this.knowledge.stems.find((target) => {
            return target.isComplete == 0;
          });
        }
        if (!unfinished) {
          this.$emit("skip_activity", item);
        } else {
          this.$message({
            message: "您当前未答完题,请点击进入,继续答题",
            type: "warning",
          });
        }
      }
      if (
        this.$route.path == "/activityDetail" &&
        this.utils.identity("K12做题") &&
        this.knowledge
      ) {
        let unfinished = this.knowledge.stems.find((target) => {
          return target.isComplete == 0;
        });
        if (!unfinished) {
          this.$router.push({
            path: "/comparative",
            query: { id: this.$route.query.id, caseId: item.caseId },
          });
        } else {
          this.$emit("skip_activity", item);
        }
      }
    },
  },
};
</script>
<style lang='less' scoped>
@import '../../style/task/index.less';
@import '../../style/mouse.less';
.el-scrollbar/deep/.el-scrollbar__wrap {
  overflow-x: hidden;
}
.notClick {
  pointer-events: none;
}
.n_click {
  pointer-events: none;
}
</style>