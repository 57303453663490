<!-- 筛选部分 -->
<template>
  <div class="screen">
    <span class="text">选择班级</span>
    <div class="select">
      <el-select v-model="classValue" placeholder="班级" disabled size="mini">
        <el-option
          v-for="item in classOptions"
          :key="item.id"
          :label="item.className"
          :value="item.id"
        ></el-option>
      </el-select>
      <el-select
        v-model="studentValue"
        placeholder="请选择学生"
        @change="searchStudent"
        size="mini"
        v-if="!showReset&&$route.path == '/topicDetails'&&show"
      >
        <el-option
          v-for="item in studentOptions"
          :key="item.id"
          :label="item.studentName"
          :value="item.id"
        ></el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
import { classList } from '../../api/api'
export default {
  props: {
    knowledge: { type: Object, default: () => { } },
    show: { type: Boolean, default: true }
  },
  data () {
    return {
      subjectValue: '',
      classOptions: [],
      studentOptions: [],
      classValue: null,
      studentValue: null,
      lecturerValue: '',
      classId: null,
      showReset: false,
      className: null,
      status: 0,//0  老师  1  学生
    }
  },
  watch: {
    knowledge (val, old) {
      if (val !== old) {
        let arr = []
        val.users.map(item => {
          let obj = {}
          obj['studentName'] = item.userName
          obj['id'] = item.userId
          arr.push(obj)
        })
        this.studentOptions = arr
      }
    }
  },
  components: {},
  created () {
  },
  mounted () {
    this.load()
    setTimeout(() => {
      if (this.$route.query.classId) {
        this.classValue = Number(this.$route.query.classId)
      }
    }, 100);
    this.analysis()
  },
  methods: {
    // 解析appIds
    analysis () {
      if (this.utils.identity('K12做题')) {
        this.status = 1
        this.showReset = true
      }
    },
    // 选择学生
    searchStudent (val) {
      this.$emit('searchStudent', val)
    },
    load () {
      // 获取班级
      classList({}).then(res => {
        if (res.data.list.length !== 0) {
          res.data.list.map(item => {
            if (item.id == this.$route.query.classId) {
              this.className = item.className
              this.classValue = item.id
            }
          })
          if (this.status == 0) {
            res.data.list.map(item => {
              if (item.id == this.$route.query.classId) {
                this.className = item.className
                this.classValue = item.id
                this.$emit('searchClassButton', item)
              }
            })
          } else if (this.status == 1 && this.$route.query.idType) {
            this.$route.query['id'] = this.$route.query.classId
            this.$emit('searchClassButton', this.$route.query)
          } else {
            this.$emit('searchClassButton', { id: this.$route.query.classId })
          }
          this.classOptions = res.data.list;
          this.$emit('classData', this.classOptions)
        }
      })
    },
    reset () {
      this.subjectValue = ''
      this.classValue = null
      this.lecturerValue = ''
      this.$emit('searchClassButton', { id: null })
    }
  }
}

</script>
<style lang='less' scoped>
@import '../../style/mouse.less';
.screen {
  margin-bottom: 20px;
  .select {
    display: inline-block;
    width: 55%;
    .el-select {
      width: 20%;
    }
  }
  .text {
  }
  .el-select {
    margin-left: 10px;
  }
  /deep/.el-button {
    width: 10% !important;
  }
}
</style>
