import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/main.vue'
import topicDetails from '../components/subject'
import knowledgePoints from '../views/knowledge-points'

import {
	obtainToken
} from "../api/api.js" // 用tokenkey换取token的接口

Vue.use(VueRouter)

const routes = [{
		path: '/',
		name: 'main',
		component: resolve => require(['../views/main.vue'], resolve),
		redirect: '/home',
		children: [{
				path: '/home',
				name: 'home',
				component: resolve => require(['@/views/homePage/index.vue'], resolve),
				meta: {
					title: '首页',
					haveChild: false,
					iconClass: 'el-icon-s-home',
					isShow: true,
					teacherShow: false
				},
			}, {
				path: '/classRoom',
				name: 'class-room',
				component: resolve => require(['@/views/class-room/index.vue'], resolve),
				meta: {
					title: '班级',
					haveChild: false,
					iconClass: 'el-icon-menu',
					isShow: true,
					teacherShow: true
				},
			},
			{
				path: '/activity',
				name: 'activity-manage',
				component: resolve => require(['@/views/activity-manage/index.vue'], resolve),
				meta: {
					title: '教学',
					haveChild: false,
					iconClass: 'el-icon-menu',
					isShow: false,
					teacherShow: true
				},
			},
			{
				path: '/chart',
				name: 'chart-manage',
				component: resolve => require(['@/views/chart-manage/index.vue'], resolve),
				meta: {
					title: '学情分析',
					haveChild: false,
					iconClass: 'el-icon-s-data',
					isShow: true,
					teacherShow: false,
					isKeepAlive: true
				},
			},
			{
				path: '/wrongBook',
				name: 'wrong-book',
				component: resolve => require(['@/views/wrong-book/index.vue'], resolve),
				meta: {
					title: '错题本',
					haveChild: false,
					iconClass: 'el-icon-s-management',
					isShow: true,
					teacherShow: false
				},
			},
			{
				path: '/materialGather/TestAnswer',
				name: 'TestAnswer',
				component: resolve => require(['@/views/Test-answer/index.vue'], resolve),
				meta: {
					title: '试卷答题',
					haveChild: false,
					isShow: true,
					teacherShow: false
				},
			},

			{
				path: '/materialGather',
				name: 'materialGather',
				component: resolve => require(['@/views/main_question_bank.vue'], resolve),
				meta: {
					title: '资料库',
					haveChild: false,
					iconClass: 'el-icon-s-management',
					isShow: true,
					teacherShow: false
				},
				children: [{
						path: '/materialGather/k12SetQuestion',
						name: 'k12SetQuestion',
						meta: {
							title: 'k12出题',
							haveChild: false,
							isShow: true,
							teacherShow: false
						},
						component: resolve => require(['@/views/materialGather/k12SetQuestion/index.vue'], resolve),
					},
					{
						path: '/materialGather/labelManagemen',
						name: 'labelManagemen',
						meta: {
							title: '标签管理',
							haveChild: false,
							isShow: true,
							teacherShow: false,
							doubleDeck: false,
						},
						component: resolve => require(['@/views/main_question_bank.vue'], resolve),
						children: [{
								path: '/materialGather/questionBank',
								name: 'questionBank',
								meta: {
									title: '题库',
									haveChild: false,
									isShow: true,
									teacherShow: false,

								},
								component: resolve => require(['@/views/questionBank/index.vue'], resolve),
							},
							{
								path: '/materialGather/materialGather_list',
								name: 'materialGather_list',
								meta: {
									title: '标签',
									haveChild: false,
									isShow: true,
									teacherShow: false,

								},
								component: resolve => require(['@/views/materialGather/index.vue'], resolve),
							},
						]
					},

					{
						path: '/materialGather/video',
						name: 'video',
						meta: {
							title: '学习视频',
							haveChild: false,
							isShow: true,
							teacherShow: false
						},
						component: resolve => require(['@/views/video/index.vue'], resolve),
					},
					{
						path: '/carryOutVideo',
						name: 'carryOutVideo',
						meta: {
							title: '视频答题页',
							haveChild: false,
							isShow: true,
							teacherShow: false
						},
						component: resolve => require(['@/components/carryOutVideo/index.vue'], resolve),
					},
					{
						path: '/materialGather/newExaminationPaper',
						name: 'newExaminationPaper',
						meta: {
							title: '创建试卷',
							haveChild: false,
							isShow: true,
							teacherShow: false
						},
						component: resolve => require(['@/views/examination/create/index.vue'], resolve),
					},
					{
						path: '/materialGather/PaperTest',
						name: 'PaperTest',
						meta: {
							title: '小测验',
							haveChild: false,
							isShow: true,
							teacherShow: false,
							doubleDeck: false,
						},
						component: resolve => require(['@/views/main_question_bank.vue'], resolve),
						children: [{
								path: '/materialGather/PaperTest',
								name: 'PaperTest',
								meta: {
									title: '卷题管理',
									haveChild: false,
									isShow: true,
									teacherShow: true
								},
								component: resolve => require(['@/views/materialGather/PaperTest/index.vue'], resolve),
							},
							{
								path: '/materialGather/titleList',
								name: 'titleList',
								meta: {
									title: '题目列表',
									haveChild: false,
									isShow: true,
									teacherShow: true
								},
								component: resolve => require(['@/views/materialGather/titleList/index.vue'], resolve),
							},
						]
					},
				]
			},
			{
				path: '/vocationalEducation',
				name: 'vocationalEducation',
				meta: {
					title: '题目管理',
					haveChild: false,
					isShow: true,
					teacherShow: false,
					iconClass: 'el-icon-s-management',
				},
				component: resolve => require(['@/views/main_question_bank.vue'], resolve),
				children: [{
						path: '/vocationalEducation/course',
						name: 'course',
						meta: {
							title: '课程管理',
							haveChild: false,
							isShow: true,
							teacherShow: false,

						},
						component: resolve => require(['@/views/vocationalEducation/course/index.vue'], resolve),
					},
					{
						path: '/vocationalEducation/specialTopic',
						name: 'specialTopic',
						meta: {
							title: '专题管理',
							haveChild: false,
							isShow: true,
							teacherShow: false,
						},
						component: resolve => require(['@/views/vocationalEducation/specialTopic/index.vue'], resolve),
					},
				]
			},
			{
				path: '/activityDetail',
				name: 'activityDetail',
				component: resolve => require(['@/views/knowledge-points/index.vue'], resolve),
				meta: {
					title: '活动详情',
					haveChild: false,
					iconClass: 'el-icon-s-data',
					isShow: false
				},
			}, {
				path: '/topicDetails',
				name: 'topicDetails',
				component: topicDetails,
				meta: {
					title: '题目详情',
					haveChild: false,
					iconClass: 'el-icon-s-home',
					isShow: false,
				},
			},
			{
				path: '/answerPage',
				name: 'answerPage',
				component: resolve => require(['@/components/answerPage/index.vue'], resolve),
				meta: {
					title: '答题页',
					haveChild: false,
					iconClass: 'el-icon-s-home',
					isShow: false
				}
			},
			{
				path: '/Preview',
				name: 'preview',
				component: resolve => require(['@/views/materialGather/k12SetQuestion/preview.vue'], resolve),
				meta: {
					title: '预览变式',
					haveChild: false,
					iconClass: 'el-icon-s-data',
					isShow: false,
				},
			},
			{
				path: '/GuangzhouPreview',
				name: 'Guangzhoupreview',
				component: resolve => require(['@/views/vocationalEducation/specialTopic/components/preview.vue'], resolve),
				meta: {
					title: '预览变式',
					haveChild: false,
					iconClass: 'el-icon-s-data',
					isShow: false,
				},
			},
			{
				path: '/chartK12Detail',
				name: 'chartK12Detail',
				component: resolve => require(['@/components/k12Details/index.vue'], resolve),
				meta: {
					title: '学情K12详情',
					haveChild: false,
					iconClass: 'el-icon-s-data',
					isShow: false
				},
			},
			{
				path: '/chartIMMEXDetail',
				name: 'chartIMMEXDetail',
				component: resolve => require(['@/components/immexDetails/index.vue'], resolve),
				meta: {
					title: '学情IMMEX详情',
					haveChild: false,
					iconClass: 'el-icon-s-data',
					isShow: false
				},
			}, {
				path: '/chartStudents',
				name: 'chartStudents',
				component: resolve => require(['@/views/students-manage/index.vue'], resolve),
				meta: {
					title: '学情详情',
					haveChild: false,
					iconClass: 'el-icon-s-data',
					isShow: true,
					teacherShow: false,
				},
			}, {
				path: '/personalCenter',
				name: 'personalCenter',
				component: resolve => require(['@/components/personalCenter/index.vue'], resolve),
				meta: {
					title: '个人中心',
					haveChild: false,
					iconClass: 'el-icon-s-data',
					isShow: false
				},
			}, {
				path: '/educationMail',
				name: 'educationMail',
				component: resolve => require(['@/components/educationMail/index.vue'], resolve),
				meta: {
					title: '站内信',
					haveChild: false,
					iconClass: 'el-icon-s-data',
					isShow: false
				},
			}, {
				path: '/statisticalTable',
				name: 'statisticalTable',
				component: resolve => require(['@/views/statisticalTable/index.vue'], resolve),
				meta: {
					title: '统计表',
					haveChild: false,
					iconClass: 'el-icon-s-data',
					isShow: false
				},
			},
			{
				path: '/comparative',
				name: 'comparative',
				component: resolve => require(['@/components/comparative/index.vue'], resolve),
				meta: {
					title: '对比学习',
					haveChild: false,
					iconClass: 'el-icon-s-data',
					isShow: false
				},
			},
			{
				path: '/tagChart',
				name: 'tagChart',
				component: resolve => require(['@/components/tagChart/index.vue'], resolve),
				meta: {
					title: '标签图表',
					haveChild: false,
					iconClass: 'el-icon-s-data',
					isShow: false,
					teacherShow: false
				},
			},
			{
				path: '/materialGather/particulars',
				name: 'particulars',
				component: resolve => require(['@/views/materialGather/k12SetQuestion/particulars.vue'], resolve),
				meta: {
					title: 'k12变式详情',
					haveChild: false,
					iconClass: 'el-icon-s-data',
					isShow: false,
					teacherShow: false
				},
			},
			{
				path: '/materialGather/titleList/edit',
				name: 'Edit',
				component: resolve => require(['@/views/materialGather/titleList/edit.vue'], resolve),
				meta: {
					title: '编辑题目',
					haveChild: false,
					iconClass: 'el-icon-s-data',
					isShow: false,
					teacherShow: false
				},
			},
			{
				path: '/adultEducation/answer',
				name: 'adultEducationAnswer',
				component: resolve => require(['@/views/adultEducation/adultEducation-answer/index.vue'], resolve),
				meta: {
					title: '成人-答题页',
					haveChild: false,
					iconClass: 'el-icon-s-data',
					isShow: false,
					teacherShow: false
				},
			},
			{
				path: '/adultEducation/teacher/ActivityDetails',
				name: 'adultEducationTeacherActivityDetails',
				component: resolve => require(['@/views/adultEducation/TeacherActivityDetails/index.vue'], resolve),
				meta: {
					title: '成人-老师活动详情页',
					haveChild: false,
					iconClass: 'el-icon-s-data',
					isShow: false,
					teacherShow: false
				},
			}, {
				path: '/adultActivityList',
				name: 'adultActivityList',
				component: resolve => require(['@/views/adultStudents/adultActivityList/index.vue'], resolve),
				meta: {
					title: '成人-活动列表',
					haveChild: false,
					iconClass: 'el-icon-s-home',
					isShow: false,
					teacherShow: false
				}
			},
			{
				path: '/adultActivityDetails',
				name: 'adultActivityDetails',
				component: resolve => require(['@/views/adultStudents/adultActivityDetails/index.vue'], resolve),
				meta: {
					title: '成人-活动详情',
					haveChild: false,
					iconClass: 'el-icon-s-home',
					isShow: false,
					teacherShow: false
				}
			},
			{
				path: '/adultSummary',
				name: 'adultSummary',
				component: resolve => require(['@/views/adultStudents/adultSummary/index.vue'], resolve),
				meta: {
					title: '成人-答题总结',
					haveChild: false,
					iconClass: 'el-icon-s-home',
					isShow: false,
					teacherShow: false
				}
			},
			{
				path: '/TeacherLookSubActivity',
				name: 'TeacherLookSubActivity',
				component: resolve => require(['@/views/adultEducation/TeacherLookSubActivity/index.vue'], resolve),
				meta: {
					title: '老师查看学生相关',
					haveChild: false,
					iconClass: 'el-icon-s-home',
					isShow: false,
					teacherShow: false
				}
			},
			{
				path: '/questions',
				name: 'Questions',
				component: resolve => require(['@/views/adultStudents/questions/index.vue'], resolve),
				meta: {
					title: '学生答疑页面',
					haveChild: false,
					iconClass: 'el-icon-s-home',
					isShow: false,
					teacherShow: false
				}
			},
			{
				path: '/teacherquestions',
				name: 'TeacherQuestions',
				component: resolve => require(['@/views/adultEducation/TeacherQuestions/index.vue'], resolve),
				meta: {
					title: '老师答疑页面',
					haveChild: false,
					iconClass: 'el-icon-s-home',
					isShow: false,
					teacherShow: false
				}
			},
			{
				path: '/urge',
				name: 'Urge',
				component: resolve => require(['@/views/adultStudents/urge/index.vue'], resolve),
				meta: {
					title: '学生督促',
					haveChild: false,
					iconClass: 'el-icon-s-home',
					isShow: false,
					teacherShow: false
				}
			}, 
			{
				path: '/teacherurge',
				name: 'TeacherUrge',
				component: resolve => require(['@/views/adultEducation/TeacherUrge/index.vue'], resolve),
				meta: {
					title: '老师督促',
					haveChild: false,
					iconClass: 'el-icon-s-home',
					isShow: false,
					teacherShow: false
				}
			}, 
			{
				path: '/courseware',
				name: 'courseware',
				component: resolve => require(['../views/courseware/index.vue'], resolve),
				meta: {
					title: '课件管理',
					haveChild: false,
					iconClass: 'el-icon-folder-opened',
					isShow: true,
					teacherShow: true
				},
			},




			// 
		]
	},

]

const router = new VueRouter({
	routes,
	scrollBehavior(to, from, savedPosition) {
		// 从第二页返回首页时savedPosition为undefined
		if (savedPosition || typeof savedPosition === 'undefined') {
			// 只处理设置了路由元信息的组件
			from.meta.isKeepAlive = typeof from.meta.isKeepAlive === 'undefined' ? undefined : false
			to.meta.isKeepAlive = typeof to.meta.isKeepAlive === 'undefined' ? undefined : true
			if (savedPosition) {
				return savedPosition
			}
		} else {
			from.meta.isKeepAlive = typeof from.meta.isKeepAlive === 'undefined' ? undefined : true
			to.meta.isKeepAlive = typeof to.meta.isKeepAlive === 'undefined' ? undefined : false
		}
	}
})
let Base64 = require('js-base64').Base64;
let jwt = require("jsonwebtoken");



function getQueryVariable(variable)
{
	variable = variable.toLowerCase();
    var query = window.location.search.substring(1).toLowerCase();
	var vars = query.split("&");
    for (var i=0;i<vars.length;i++) {
		var pair = vars[i].split("=");
		if(pair[0] == variable){return pair[1];}
	}
	return(false);

}
router.beforeEach((to, from, next) => { //路由拦截器

	if (window._hmt) {
		if (to.path) {
			window._hmt.push(['_trackPageview', '/#' + to.fullPath])
		}
	}
	var urlAppId = getQueryVariable("appid");
	if(urlAppId == false){
		next();	
	}
	let appIds = JSON.parse(localStorage.getItem('appIds'))
	let token1 = null
	window.localStorage.setItem("toPath", to.path);
	window.localStorage.setItem("toName", to.name);
	// ================================
	if (appIds) {
		for (let i = 0; i < appIds.length; i++) {
			if (appIds[i].appId == urlAppId) {
				token1 = appIds[i].token
			}
		}
	}
	// =================================
	let token = token1;
	if (!token) {
		let name = 'tokenKey';
		let tokenKey = decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
		//如果没有token，需要判断url中是否存在token key，如果有的话去sso中换回真实的token
		if (tokenKey) {
			obtainToken({
					tokenKey: tokenKey
				})
				.then(res => {
					if (res.err_CODE === 0) {
						let menu = res.data.menu;
						let newToken = res.data.authorityToken;
						//token存在时对后台返回数据进行base64解码存储
						if (newToken) {
							let payload = jwt.decode(newToken);
							let appInfos = JSON.parse(window.localStorage.getItem('appIds'));
							if (appInfos) {
								var appExist = false;
								appInfos.forEach(item => {
									if (item.appId === urlAppId) {
										item.token = newToken;
										item.userId = payload.user_id;
										item.userName = payload.user_name;
										item.tenantId = payload.tenant_id;
										item.role = payload.role;
										item.fullName = payload.full_name;
										item.fullAccess = res.data.allData ? 'fullAccess' : '';
										item.menu = menu ? Base64.decode(menu) : null;
										appExist = true;
									} else {
										appInfos.push({
											appId: urlAppId,
											token: newToken,
											userId: payload.user_id,
											userName: payload.user_name,
											tenantId: payload.tenant_id,
											role: payload.role,
											fullName: payload.full_name,
											fullAccess: res.data.allData ? 'fullAccess' : '',
											menu: menu ? Base64.decode(menu) : null
										})
									}
								});

								if (!appExist) {
									appInfos.push({
										appId: urlAppId,
										token: newToken,
										userId: payload.user_id,
										userName: payload.user_name,
										tenantId: payload.tenant_id,
										role: payload.role,
										fullName: payload.full_name,
										fullAccess: res.data.allData ? 'fullAccess' : '',
										menu: menu ? Base64.decode(menu) : null
									})
								}
							} else {
								appInfos = [];
								appInfos.push({
									appId: urlAppId,
									token: newToken,
									userId: payload.user_id,
									userName: payload.user_name,
									tenantId: payload.tenant_id,
									role: payload.role,
									fullName: payload.full_name,
									fullAccess: res.data.allData ? 'fullAccess' : '',
									menu: menu ? Base64.decode(menu) : null
								});
							}
							localStorage.setItem('appIds', JSON.stringify(appInfos))
						}
					}
					next();
				});
		} else {
			next();
		}
	} else {
		next();
	}
});



export default router