<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data () {
    return {
    };
  },
  beforeDestroy () {

  },
  watch: {
    $route: {
      handler (val, old) {
        let arr = ['/chartStudents', '/chartK12Detail', '/statisticalTable']
        // 老师点击学生概况&班级效率效果图
        let val_path = arr.find(item => { return val.path == item })
        if (val_path && old.path == '/chart') {
          if (val_path == '/chartK12Detail') {
            let classId = JSON.parse(val.query.searchInfo)
            this.$store.commit('setClassId', classId.classId)
          } else {
            this.$store.commit('setClassId', val.query.classId)
          }
        }
        // 如果跳转的路由不是学情,则重置classId
        let old_path = arr.find(item => { return old.path == item })
        if (val.path !== '/chart' && old_path) {
          this.$store.commit('replacement_classId')
        }
        // 清除定时器
        if (this.$route.path !== '/topicDetails' && this.$route.path !== '/materialGather/TestAnswer') {
          clearInterval(this.$store.state.TimeId)
        }
      },
      // 深度观察监听
      deep: true
    }
  },
  methods: {
    listenPage () {

    }
  },
  mounted () {
    this.listenPage()
  }


};
</script>




<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}
.el-popover {
  overflow: auto;
}
.el-scrollbar .el-scrollbar__wrap {
  overflow-x: hidden;
}
// 上面隐藏横向滚动条会导致下拉框底部遮盖问题
.el-select-dropdown .el-scrollbar {
  padding-bottom: 17px;
}
.el-empty {
  padding: 70px 0;
}
.el-cascader__dropdown {
  height: 400px;
}
.el-cascader-panel {
  height: 400px;
}
.el-cascader-menu__wrap {
  height: 400px !important;
}
.tox-tinymce-aux {
  z-index: 2300 !important;
}

// .el-popper {
//   height: 100px;
//   .el-cascader-panel {
//     height: 100%;
//   }
//   .el-cascader-menu__wrap {
//     height: 100% !important;
//   }
// }
</style>
