<!--  我是标签内的答题页面 -->
<template>
  <div v-if="data.length">
    <Collapse
      v-model="openId"
      v-for="(item, index) in data"
      :key="index"
      style="margin-bottom: 20px"
    >
      <Panel :name="item.id.toString()">
        {{ item.name }}
        <div slot="content">
          <div v-for="(tag, idx) in item.tag" :key="idx">
            <div
              class="label-item"
              v-if="ProfessorOrNot == '提交答案' && completionStatus"
            >
              <span class="label-like" style="text-align: left"
                ><el-button
                  style="margin-right: 15px"
                  type="success"
                  size="mini"
                  @click="onClickAnalysis(tag)"
                  >{{
                    tag.onAnalysis.show == 0 ? "查看解析" : "隐藏解析"
                  }}</el-button
                ></span
              >
            </div>
            <!-- 数值 -->
            <div class="label-item" v-if="tag.type == 1">
              <span class="label-like">{{ `${tag.name}：` }}</span>
              <el-input-number
                :disabled="completionStatus"
                v-model="tag.answer"
                @change="onChangeNumber"
                :placeholder="
                  tag.numberLowerLimit + '到' + tag.numberSupperLimit + '之间'
                "
                :min="tag.numberLowerLimit"
                :max="tag.numberSupperLimit"
                size="small"
              ></el-input-number>
              <p style="line-height: 30px; display: block; margin-left: 10px">
                {{
                  "(请输入" +
                  tag.numberLowerLimit +
                  "到" +
                  tag.numberSupperLimit +
                  "之间的数)"
                }}
              </p>
            </div>
            <!-- 多选 -->
            <div class="label-item" v-if="tag.type == 3">
              <span class="label-like">{{ `${tag.name}：` }}</span>
              <el-checkbox-group
                :disabled="completionStatus"
                style="width: 70%; line-height: 30px"
                v-model="tag.answer"
                @change="onChangeCheckbox"
              >
                <el-checkbox
                  :label="option.name"
                  v-for="option in tag.content"
                  :key="option.id"
                  :style="style(option, tag.answer)"
                  class="checkbox"
                ></el-checkbox>
              </el-checkbox-group>
            </div>
            <!-- 单选 -->
            <div class="label-item" v-if="tag.type == 2">
              <span class="label-like"> {{ `${tag.name}：` }}</span>
              <el-radio-group
                :disabled="completionStatus"
                v-model="tag.answer"
                style="width: 70%; line-height: 45px"
                @change="onChangeRadio(tag)"
              >
                <el-radio
                  :label="option.name"
                  v-for="option in tag.content"
                  :key="option.name"
                  :style="MultipleChoice(tag.answer, option.name)"
                  >{{ option.name }}</el-radio
                >
              </el-radio-group>
            </div>
            <!-- 文本 -->
            <div class="label-item" v-if="tag.type == 4">
              <span class="label-like">{{ `${tag.name}：` }}</span>
              <el-input
                :disabled="completionStatus"
                maxlength="50"
                show-word-limit
                @input="onChangeInput"
                v-model="tag.answer"
                placeholder="请输入内容"
                style="width: 200px"
                clearable
                size="small"
              ></el-input>
            </div>
            <!-- 受训人查看解析 -->

            <!-- 专家和受训人显示的解析 -->
            <div v-if="ProfessorOrNot == '提交参考答案'">
              <span class="label-like-analysis">解析：</span>
              <el-input
                :disabled="completionStatus"
                maxlength="50"
                show-word-limit
                @input="onChangeInput"
                v-model="tag.analysis"
                placeholder="请输入内容"
                style="width: 200px"
                clearable
                size="small"
              ></el-input>
            </div>
            <!-- 查看专家的 -->
            <div
              v-if="ProfessorOrNot == '提交答案' && completionStatus"
              class="label-item"
            >
              <span class="label-like">参考答案：</span>

              <div style="line-height: 32px" v-if="!tag.referenceVersion">
                暂无
              </div>

              <el-input-number
                v-if="tag.type == 1 && tag.referenceVersion"
                :disabled="completionStatus"
                v-model="tag.referenceVersion"
                @change="onChangeNumber"
                :placeholder="
                  tag.numberLowerLimit + '到' + tag.numberSupperLimit + '之间'
                "
                :min="tag.numberLowerLimit"
                :max="tag.numberSupperLimit"
                size="small"
              ></el-input-number>
              <el-checkbox-group
                :disabled="completionStatus"
                style="width: 70%; line-height: 30px"
                v-model="tag.referenceVersion"
                v-if="tag.type == 3 && tag.referenceVersion"
                @change="onChangeCheckbox"
              >
                <el-checkbox
                  :label="option.name"
                  v-for="option in tag.content"
                  :key="option.id"
                  :style="style(option, tag.referenceVersion)"
                  class="checkbox"
                ></el-checkbox>
              </el-checkbox-group>
              <el-radio-group
                :disabled="completionStatus"
                v-model="tag.referenceVersion"
                v-if="tag.type == 2 && tag.referenceVersion"
                style="width: 70%; line-height: 45px"
                @change="onChangeRadio(tag)"
              >
                <el-radio
                  :label="option.name"
                  v-for="option in tag.content"
                  :key="option.name"
                  :style="MultipleChoice(tag.referenceVersion, option.name)"
                  >{{ option.name }}</el-radio
                >
              </el-radio-group>
              <el-input
                :disabled="completionStatus"
                maxlength="50"
                v-if="tag.type == 4 && tag.referenceVersion"
                show-word-limit
                @input="onChangeInput"
                v-model="tag.referenceVersion"
                placeholder="暂无"
                style="width: 200px"
                clearable
                size="small"
              ></el-input>

              <!-- <span>
                {{ tag.referenceVersion ? tag.referenceVersion : "暂无" }}</span
              > -->
            </div>
            <div v-if="tag.onAnalysis.show == 1">
              <span class="label-like-analysis">专家解析：</span>
              <el-input
                :disabled="completionStatus"
                maxlength="50"
                show-word-limit
                v-model="tag.ExpertAnalysis"
                placeholder="暂无"
                style="width: 200px"
                clearable
                type="textarea"
                size="small"
              ></el-input>
            </div>

            <el-divider></el-divider>
          </div>
          <CollapseItem
            :data="item.children"
            :ProfessorOrNot="ProfessorOrNot"
            :completionStatus="completionStatus"
            :openId="openId"
            @change-label-data="changeLableData"
            :num="num"
            @chang-Expert-Analysis="onClickAnalysisS"
          />
        </div>
      </Panel>
    </Collapse>
  </div>
</template>

<script>
import CollapseItem from "./index";

export default {
  name: "CollapseItem",
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    num: {},
    ProfessorOrNot: {},
    completionStatus: {},
    openId: {},
  },
  data() {
    return {
      lookAnalysis: 0,
    };
  },
  components: {
    CollapseItem,
  },
  methods: {
    //样式
    style(datalist, answerlist) {
      var type = 0;
      answerlist.map((item, index) => {
        if (datalist.name == item) {
          type = 1;
        }
      });
      // if (type == 1 && this.completionStatus) {
      return "white-space: normal;";
      // } else {
      //   return "white-space: normal";
      // }
    },
    MultipleChoice(answer, name) {
      // if (answer == name && this.completionStatus) {
      return "white-space: normal;";
      // } else {
      //   return "white-space: normal;";
      // }
    },
    //初始化
    onClickAnalysis(tag) {
      if (tag.onAnalysis.show == 1) {
        tag.onAnalysis.show = 0;
      } else {
        tag.onAnalysis.show = 1;
      }
      tag.onAnalysis.con = 1;
      this.$emit("chang-Expert-Analysis", tag);
      this.$forceUpdate();
    },
    onClickAnalysisS(tag) {
      this.$emit("chang-Expert-Analysis", tag);
    },
    //单选
    onChangeRadio(e) {
      this.$forceUpdate(); //处理完数据 需要强制刷新
      this.$emit("change-label-data", this.data);
    },
    //多选
    onChangeCheckbox(e) {
      this.$forceUpdate();
      this.$emit("change-label-data", this.data);
    },
    //文本
    onChangeInput(e) {
      this.$forceUpdate();
      this.$emit("change-label-data", this.data);
    },
    //数值
    onChangeNumber(e) {
      this.$forceUpdate();
      this.$emit("change-label-data", this.data);
    },
    //多级维度
    changeLableData(payload) {
      this.$emit("change-label-data", payload);
    },
  },
  mounted() {},
  created() {},
  watch: {
    data(newV, oldN) {
      if (newV !== oldN) {
        this.$forceUpdate();
      }
    },
    num(newV, oldN) {
      if (newV !== oldN) {
        //通过传过来的num 题目id 来判断是不是需要强制刷新
        this.$forceUpdate();
      }
    },
  },
};
</script>

<style lang='less' scoped >
@import "../../style/mouse.less";
@import "./style.less";
/deep/.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
  background-color: #409eff;
  border-color: #409eff;
}
/deep/.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #409eff;
}
/deep/ .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner:after {
  border-color: #fff;
}

/deep/.el-radio__input.is-disabled.is-checked .el-radio__inner {
  background-color: #409eff;
  border-color: #409eff;
}
/deep/.el-radio__input.is-checked + .el-radio__label {
  color: #409eff;
}

/deep/.el-radio__input.is-disabled.is-checked .el-radio__inner::after {
  background-color: #fff;
}
</style>