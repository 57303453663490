<!-- 知识点 -->
<template>
  <div class="knowledge">
    <screen @classData="classData"></screen>
    <el-button class="participate" @click="Participate" plain>参与学生</el-button>
    <div class="outside">
      <el-card class="box-card-top">
        <span class="text-title">{{ knowledge.title }}</span>
        <span>活动描述</span>
        <span>{{ knowledge.description }}</span>
        <span>活动指导</span>
        <span>{{ knowledge.teachingMessage }}</span>
      </el-card>
      <div class="content">
        <!-- 左侧 -->
        <el-card class="box-card-left">
          <el-scrollbar style="height: 100%">
            <activity-Task :knowledge="knowledge" @skip_activity="skip_activity"></activity-Task>
          </el-scrollbar>
        </el-card>
        <!-- 右侧 -->
        <el-card class="box-card-right">
          <el-scrollbar style="height: 100%" class="right_scroll">
            <span class="interaction">互动讨论</span>
            <el-tabs v-model="activeName" @tab-click="heatDegree($event)">
              <el-tab-pane label="按热度" name="1"></el-tab-pane>
              <el-tab-pane label="按时间" name="0"></el-tab-pane>
            </el-tabs>
            <!-- 评论 -->
            <div>
              <div class="comment" v-for="(item, index) in comments" :key="index">
                <el-card class="box-card-comment">
                  <el-avatar>{{ item.headPortrait }}</el-avatar>
                  <div class="comment-content">
                    <!-- 数据待定 -->
                    <span class="name">{{ item.userName }}</span>
                    <p
                      style="word-wrap: break-word; word-break: break-all; line-height:15px"
                    >{{ item.commentMessage }}</p>
                    <span class="time">{{ item.createTime }}</span>
                    <i class="el-icon-chat-dot-round" style="position: relative;">
                      <span class="reply-number">{{ item.replyNumber }}</span>
                      <span
                        class="el-icon-delete deleteComments"
                        @click="deleteComments(item,1)"
                        v-fence="{ data: '', functions: ['删除评论'] }"
                      ></span>
                    </i>
                    <el-input
                      @keyup.enter.native="replyComments(item, index)"
                      v-model="item.label"
                      placeholder="写下你的观点，按回车发送~"
                    ></el-input>
                  </div>
                </el-card>
                <el-card class="box-card-reply">
                  <div class="replyComments" v-for="(itm, i) in item.replyList" :key="i">
                    <el-avatar
                      src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
                    ></el-avatar>
                    <!-- 数据待定 -->
                    <span class="name">{{ itm.userName + "：" }}</span>
                    <p class="reply">{{ itm.replyMessage }}</p>
                    <span
                      class="el-icon-delete deleteComments"
                      @click="deleteComments(itm,2)"
                      v-fence="{ data: '', functions: ['删除评论'] }"
                    ></span>
                    <span class="time">{{ itm.createTime }}</span>
                  </div>
                </el-card>
              </div>
            </div>
            <el-input
              class="publishComment"
              @keyup.enter.native="addComment"
              v-model="publish"
              placeholder="请发表你的看法吧"
            ></el-input>
          </el-scrollbar>
        </el-card>
      </div>
    </div>
    <div
      :class="
        this.$store.state.toggle_collapsed
          ? 'activity-button wth'
          : 'activity-button w100'
      "
    >
      <el-button @click="goinfo" type="primary" v-if="!completionStatus">进入</el-button>
      <el-button
        v-if="completionStatus"
        type="primary"
        style="padding-right: 14px"
        @click="viewReport"
      >查看问题报告</el-button>
      <el-button @click="go_back">返回</el-button>
    </div>
    <!-- 弹窗 -->
    <el-dialog title="一起来挑战吧" :visible.sync="dialogVisible" width="30%">
      <span>快和小伙伴一起来PK吧,就等你了~</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="infoSubject">接受挑战</el-button>
        <el-button @click="infoSubject">暂不挑战</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="showDialog" width="30%" class="warning">
      <span>该班级下还有更紧急的任务!</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="jump">前 往</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="time_status" width="30%" class="warning">
      <span>该活动已结束！再次进入则会展示做题记录！</span>
    </el-dialog>
    <el-dialog title="参与学生" :visible.sync="dialogTableVisible" width="350px">
      <el-table :data="knowledge.users" v-if="showParticipate" height="500">
        <el-table-column property="userName" label="姓名" width="300px"></el-table-column>
      </el-table>
      <el-table :data="knowledgeUser" v-if="!showParticipate" height="500">
        <el-table-column property="user" label="帮扶人" width="150px"></el-table-column>
        <el-table-column property="beuser" label="被帮扶人" width="150px"></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import activityTask from "@/components/activityTask";
import screen from "@/components/screen";
import timer from "@/components/timer_s";
import subject from "@/components/subject";
import { activeDetails, activeCommentDelete } from "../../api/api";
import { activeComment } from "../../api/api";
import { activeCommentAddReply } from "../../api/api";
import { activeCommentAdd } from "../../api/api";
import { activeList } from "../../api/api";

export default {
  data () {
    return {
      knowledge: [],
      comments: [],
      activeName: "1",
      dialogVisible: false,
      showDialog: false,
      input: "",
      publish: "",
      completeActivity: null,
      dialogTableVisible: false,
      showParticipate: true,
      knowledgeUser: [],
      totalTime: 0,
      completionStatus: false,
      appIdUser: null,
      loading: true,
      ProfessorOrNot: null, //判断是不是教授
      status: 0,//0  老师  1  学生
      time_status: false,//活动结束时的提示窗
      jump_num: 0,//点击按钮次数
    };
  },

  components: {
    screen,
    activityTask,
    timer,
    subject,
  },
  watch: {
  },
  created () {
    this.load();
    this.heatDegree();
    // this.getUserSpendTime();
  },
  mounted () {
    this.analysis();
  },
  methods: {
    // 子组件传来，跳转到答题页
    skip_activity () {
      this.goinfo()
    },
    classData (val) {
      let a = val.find((item) => {
        return item.id == this.$route.query.classId;
      });
      this.classMoreData = a;
    },
    // 解析appIds
    analysis () {
      this.appIdUser = this.utils.userId()
      if (this.utils.identity('K12做题')) {
        this.status = 1
      } else if (this.utils.identity('IMX做题')) {
        this.status = 1
      }
      if (this.utils.identity('提交答案')) {
        this.ProfessorOrNot = "提交答案";
      }
      if (this.utils.identity('提交参考答案')) {
        this.ProfessorOrNot = "提交参考答案";
      }
    },
    // 查看问题报告
    viewReport () {
      let paramObj = {
        activeId: this.knowledge.id,
        caseId: this.knowledge.stems[0].caseId,
        isComplete: this.knowledge.stems[0].isComplete,
        taskId: this.knowledge.stems[0].id,
        userId: Number(this.appIdUser),
        classId: this.knowledge.classId,
      };
      this.$router.push({
        name: "chartIMMEXDetail",
        params: {
          paramObj: paramObj,
        },
      });
      // this.$router.push(`/chartIMMEXDetail`)
    },
    // 显示参与的学生
    Participate () {
      this.dialogTableVisible = true;
      if (this.knowledge.type !== 2) {
        this.showParticipate = true;
      } else if (this.knowledge.type == 2) {
        this.showParticipate = false;
      }
    },
    participants () { },
    load () {
      this.loading = true;
      let id = this.$route.query.id;
      activeDetails({ id: id }).then((res) => {
        if (res.code == 0) {
          this.loading = false;
          // 判断是否是immex做完的,是显示问题报告
          if (res.data.stemType == 1 && res.data.stems[0].isComplete == 1) {
            this.completionStatus = true;
          }
          let arr = [];
          let arr1 = [];
          let arr2 = [];
          res.data.stems.map((item) => {
            item["title"] = item.stemId;
            item["completion"] = 50;
          });

          res.data.users.forEach((item) => {
            if (item.userType == 2) {
              arr.push(item.userName);
            }
            if (item.userType == 1) {
              arr1.push(item.userName);
            }
          });
          arr.forEach((item, index) => {
            let obj = {};
            obj["user"] = item;
            obj["beuser"] = arr1[index];
            arr2.push(obj);
          });
          this.knowledgeUser = arr2;
          this.knowledge = res.data;
          let userId = Number(this.utils.userId())
          _hmt.push(['_trackEvent', '活动详情', '查看', '活动:' + res.data.title, userId]);
          if (this.status == 1) {
          }
        } else {
          this.$message.error(res.message);
        }

      });
      // 判断是否还存在未完成的紧急任务
      activeList({
        pageNum: null,
        pageSize: null,
        classId: this.$route.query.classId,
      }).then((res) => {
        let time_status = true;
        let date = this.currentTime.currentTime();
        res.data.list.map((item) => {
          item.startTime = /\d{4}-\d{1,2}-\d{1,2}/g.exec(item.startTime);
          if (date < item.startTime[0] || date > item.endTime) {
            time_status = false;
          }
        });
        let arr = [];
        res.data.list.map((item) => {
          // 班级活动紧急优先程度
          if (item.priority == 1 && item.allTask !== item.completeTask && time_status && item.isStart == 1) {
            if (item.classId == Number(this.$route.query.classId)) {
              arr.push(item);
            }
          }
        });
        arr.sort((a, b) => {
          return a.createTime < b.createTime ? "1" : "-1";
        });
        // 所有未完成的紧急任务
        this.completeActivity = arr;
      });
    },
    // 回复评论
    async replyComments (data, index) {
      let detection_vacancy = data.label.trim().length
      if (detection_vacancy > 0) {
        event.target.blur();
        this.comments[index].label = data.label;
        let arr = [];
        this.comments.forEach((itm) => {
          arr.push(itm);
        });
        this.comments = arr;
        await activeCommentAddReply({
          commentId: data.id,
          replyMessage: arr[index].label,
        }).then((res) => {
          if (res.code == 0) {
            this.heatDegree();
          }
        });
        this.comments.label = "";
      } else {
        this.$message.error('输入不能为空!')
      }
    },
    // 添加评论
    addComment () {
      let detection_vacancy = this.publish.trim().length
      if (detection_vacancy > 0) {
        activeCommentAdd({
          activeId: this.$route.query.id,
          commentMessage: this.publish,
        }).then((res) => {
          this.heatDegree();
        });
        this.publish = "";
      } else {
        this.$message.error('输入不能为空!')
      }

    },
    // 删除评论
    deleteComments (val, type) {
      this.$confirm('您确定删除该评论吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        activeCommentDelete({ id: val.id, type: type }).then(res => {
          if (res.code == 0) {
            this.heatDegree()
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {
      });
    },
    // 按热度排序
    heatDegree (val) {
      if (val) {
        this.activeName = val.name;
      }
      activeComment({
        activeId: this.$route.query.id,
        pageNum: 1,
        pageSize: null,
        orderType: this.activeName,
      }).then((res) => {
        res.data.list.map((item) => {
          item["replyNumber"] = item.replyList.length;
          item["label"] = "";
          item["headPortrait"] = item.userName[0];
        });
        this.comments = res.data.list;
        return this.comments;
      });
    },
    //进入答题页
    goinfo () {
      if (this.status == 1) {
        // 判断是否做过
        if (this.knowledge.stems[0].isComplete == 1) {
          this.dialogVisible = false;
          const search = this.knowledge.stems;
          // 判断是否都是完成状态
          if (search.every((item) => item.isComplete === 1)) {
            this.infoSubject();
          } else {
            // 判断是不是优先级更高的
            if (this.completeActivity.length !== 0) {
              if (this.completeActivity[0].id == this.knowledge.id) {
                // 判断当前活动是否是紧急任务
                if (this.knowledge.priority == 1) {
                  this.dialogVisible = false;
                  this.infoSubject();
                  this.showDialog = false;
                } else if (this.knowledge.priority !== 1) {
                  // 如果当前不是紧急任务则判断是否还存在未完成的紧急任务----------------------
                  if (this.completeActivity.length == 0) {
                    this.infoSubject();
                    this.showDialog = false;
                    this.dialogVisible = false;
                  } else {
                    this.showDialog = true;
                    this.dialogVisible = false;
                  }
                }
              } else {
                this.dialogVisible = false;
                this.showDialog = true;
              }
            } else {
              this.infoSubject();
            }
          }
        } else {
          // 判断当前活动是否是紧急任务
          if (this.knowledge.priority == 1) {
            // 判断是不是优先级更高的
            if (this.completeActivity[0].id == this.knowledge.id) {
              if (this.knowledge.type !== 1) {
                //当前活动为普通或者帮扶
                this.infoSubject();
              } else {
                this.dialogVisible = true;
                this.showDialog = false;
              }
            } else {
              this.dialogVisible = false;
              this.showDialog = true;
            }
          } else if (this.knowledge.priority !== 1) {
            // 如果当前不是紧急任务则判断是否还存在未完成的紧急任务
            if (this.completeActivity.length == 0) {
              if (this.knowledge.type !== 1) {
                //当前活动为普通
                this.infoSubject();
              } else {
                this.showDialog = false;
                this.dialogVisible = true;
              }
            } else {
              this.showDialog = true;
              this.dialogVisible = false;
            }
          }
        }
      } else if (this.status == 0) {
        this.dialogVisible = false;
        this.infoSubject();
        this.showDialog = false;
      }
    },
    // 接受挑战
    infoSubject () {
      this.jump_num = this.jump_num + 1
      this.dialogVisible = false;
      let isComplete = this.knowledge.stems.find(item => { return item.isComplete == 0 })
      let date = this.currentTime.currentTime();//获取当前时间
      if (date > this.knowledge.endTime && this.status == 1) {
        this.time_status = true
      }
      if (this.jump_num == 2 || this.status == 0 || !this.time_status) {
        if (!isComplete && this.knowledge.stemType == 0 || this.time_status) {
          let id = this.$route.query.id
          this.$router.push({ path: '/comparative', query: { id: id } })
        } else if (this.knowledge.stemType == 0) {
          this.$router.push({
            path: "/topicDetails",
            // stemType
            query: { id: this.$route.query.id, classId: this.$route.query.classId, stemType: this.$route.query.stemType },
          });
        } else if (this.knowledge.stemType == 1) {
          this.$router.push({
            path: "/answerPage",
            query: { id: this.$route.query.id },
          });
        } else if (this.knowledge.stemType == 2) {//==2
          this.$router.push({
            path: "/topicDetails",
            query: { id: this.$route.query.id, classId: this.$route.query.classId, stemType: this.$route.query.stemType },
          });
        } else if (this.knowledge.stemType == 3) {
          this.$router.push({
            path: "/carryOutVideo",
            query: { id: this.$route.query.id, classId: this.$route.query.classId, stemType: this.$route.query.stemType, videoId: this.knowledge.stems[0].caseId, taskId: this.knowledge.stems[0].id },
          });
        } else if (this.knowledge.stemType == 4) {
          this.$router.push({
            path: "/materialGather/TestAnswer",
            query: { id: this.$route.query.id, classId: this.$route.query.classId, stemType: this.$route.query.stemType, activeId: this.knowledge.stems[0].activeId, caseId: this.knowledge.stems[0].caseId, isComplete: this.knowledge.stems[0].isComplete },
          });
        }
      }
    },
    go_back () {
      this.$router.push({ path: "/activity", query: { classId: this.$route.query.classId } });
    },
    jump () {
      this.$router.push({
        path: "/activityDetail",
        query: {
          id: this.completeActivity[0].id,
          classId: this.$route.query.classId,
          stemType: this.$route.query.stemType
        },
      });
      location.reload();
      this.showDialog = false;
    },
    getUserSpendTime () {
      let params = {
        activeId: Number(this.$route.query.id),
        activeType: Number(this.$route.query.stemType),
      };
      this.$api.page.getUserSpendTime(params).then((res) => {
        if (res.data) {
          this.totalTime = res.data.data;
        }
      });
    }
  },
};
</script>
<style lang='less' scoped>
@import './style/index.less';
@import '../../style/mouse.less';
.deleteComments {
  display: inline-block;
  position: absolute;
  right: 0;
  bottom: 0;
}
/deep/.el-icon-delete {
  font-size: 16px;
}
</style>
