import axios from 'axios';
const instance = axios.create({
  timeout: 50000
});
instance.interceptors.request.use(function (config) {
  var urlAppId = window.location.href.split('=')[2].split('#')[0]
  let url = config.url;
  let token1 = null
  let appIds = JSON.parse(localStorage.getItem('appIds'))
  if (appIds) {
    for (let j = 0; j < appIds.length; j++) {
      if (appIds[j].appId == urlAppId) {
        token1 = appIds[j].token
        break;
      }
    }
  } else {
    token1 = window.localStorage.getItem("token")
  }
  config.headers.Accept = "application/json";
  let token = token1;
  if (token) { //判断token是否存在
    config.headers['Authorization'] = token;
  }
  // config.headers['Content-Type'] = 'application/json;charset=UTF-8'; //数据格式转换
  config.headers['Content-Type'] = 'application/json'; //数据格式转换
  return config;
}, function (error) {
  return Promise.reject(error);
});

instance.interceptors.response.use(function (response) {
  var urlAppId = window.location.href.split('=')[2].split('#')[0]
  if (response.data.code == 0) {
    let appIds1 = JSON.parse(localStorage.getItem('appIds'))
    if (appIds1) {
      for (let w = 0; w < appIds1.length; w++) {
        if (appIds1[w].appId !== urlAppId) {
          for (var s = 0; s < appIds1.length; s++) {
            for (var j = s + 1; j < appIds1.length; j++) {
              if (appIds1[s].appId == appIds1[j].appId) {
                appIds1.splice(j, 1);
                //因为数组长度减小1，所以直接 j++ 会漏掉一个元素，所以要 j--
                j--;
                break;
              }
            }
          }
          localStorage.setItem('appIds', JSON.stringify(appIds1))
          break;
        }
      }
    } else {
      let appId1 = []
      let obj1 = {}
      obj1.appId = localStorage.getItem('appId')
      obj1.token = localStorage.getItem('token')
      appId1.push(obj1)
      localStorage.setItem('appIds', JSON.stringify(appId1))
    }
    return response;
  }
  return response;
}, function (error) {
  // debugger
  var urlAppId = window.location.href.split('=')[2].split('#')[0]
  var statusCode = error.response.status;
  let appIds = JSON.parse(localStorage.getItem('appIds'))
  let app = urlAppId
  if (statusCode === 401) {
    // 跳转到单点登录页面
    var logonUrl = error.response.data.loginUrl;
    // debugger
    let arr = logonUrl.split('&')
    arr[1] = 'appId=' + app
    if (appIds) {
      appIds.map((item, index) => {
        if (item.appId === urlAppId) {
          appIds.splice(index, 1)
          localStorage.setItem('appIds', JSON.stringify(appIds))
        }
      })
    }
    localStorage.removeItem('toPath');
    localStorage.removeItem('toName');
    logonUrl = arr.join('&')
    window.location.href = logonUrl;
  } else {
    return error;
  }
});

export default instance;