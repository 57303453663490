/**
* 左边菜单
*/
<template>
  <el-menu
    :collapse="collapsed"
    :unique-opened="true"
    @select="change_aside_menu"
    :default-active="$route.path"
    @open="handleOpen"
    @close="handleClose"
    :collapse-transition="false"
    background-color="#fff"
    text-color="#367EE7"
    active-text-color="#ffd04b"
    :class="!collapsed ? 'title-menu' : ''"
  >
    <template v-for="item in menuList">
      <el-submenu
        v-if="item.meta.isShow && item.meta.haveChild"
        :key="item.name"
        :index="item.path"
      >
        <template slot="title">
          <i :class="'icon ' + item.meta.iconClass"></i>
          <span class="title-span" >  {{ item.meta.title=='班级'?'小组':item.meta.title }}</span>
        </template>
        <el-menu-item
          v-for="(jtem, index) in item.children"
          :key="index"
          :index="jtem.path"
          >{{ jtem.meta.title=='班级'?'小组':jtem.meta.title }}</el-menu-item
        >
      </el-submenu>
      <el-submenu
        v-else-if="
          item.meta.isShow &&
          !item.meta.haveChild &&
          item.meta.teacherShow &&
          item.children
        "
        :key="item.name"
        :index="item.path"
      >
        <template slot="title">
          <i :class="'icon ' + item.meta.iconClass"></i>
          <span class="title-span">{{ item.meta.title=='班级'?'小组':item.meta.title }}</span>
        </template>

        <el-menu-item
          v-for="(jtem, index) in item.children"
          :key="index"
          :index="jtem.path"
          class="item_p"
          v-if="jtem.meta.teacherShow"
          >{{jtem.meta.title=='班级'?'小组':jtem.meta.title }}</el-menu-item
        >
        <el-submenu
          v-for="(jtem, index) in item.children"
          :key="index"
          :index="jtem.path"
          class="item_p"
          v-if="jtem.meta.doubleDeck"
        >
          <template slot="title">
            <span class="item_p">{{ jtem.meta.title }}</span>
          </template>

          <el-menu-item
            v-for="(tem, index) in jtem.children"
            :key="index"
            :index="tem.path"
            class="item_p"
            v-if="tem.meta.teacherShow"
            >{{ tem.meta.title }}</el-menu-item
          >
        </el-submenu>
      </el-submenu>
      <el-menu-item
        v-else-if="
          item.meta.isShow && !item.meta.haveChild && item.meta.teacherShow
        "
        :key="item.name"
        :index="item.path"
      >
        <i :class="'icon ' + item.meta.iconClass"></i>
        <span class="title-span" slot="title">{{  item.meta.title=='班级'?'小组':item.meta.title  }}</span>
      </el-menu-item>
    </template>
  </el-menu>
</template>
<script>
export default {
  name: "leftnav",
  data() {
    return {
      activie_index: "1",
      collapsed: false,
      menuList: [],
    };
  },
  methods: {
    handleOpen(key, keyPath) {},
    handleClose(key, keyPath) {},
    change_aside_menu(index) {
      this.activie_index = index;
      this.$router.push(index);
    },
  },
  // 创建完毕状态(里面是操作)
  created() {
    this.menuList = this.$router.options.routes[0].children;
    var urlAppId = window.location.href.split("=")[2].split("#")[0];
    let appIds = JSON.parse(localStorage.getItem("appIds"));
    if (appIds) {
      appIds.forEach((itm) => {
        if (itm.appId === urlAppId) {
          this.menuList.forEach((item) => {
            // 是否有资料库权限
            if (item.name == "materialGather") {
              if (this.utils.identity("资料库")) {
                item.meta.teacherShow = true;
              }
              item.children.forEach((childValue) => {
                // 是否有K12出题权限
                if (childValue.name == "k12SetQuestion") {
                  if (this.utils.identity("K12出题")) {
                    childValue.meta.teacherShow = true;
                  }
                }
                // 是否有学习视频权限
                if (childValue.name == "video") {
                  if (this.utils.identity("视频材料")) {
                    childValue.meta.teacherShow = true;
                  }
                }

                //是否有小测试权限
                if (childValue.name == "PaperTest") {
                  if (this.utils.identity("小测验")) {
                    childValue.meta.doubleDeck = true;
                  }
                }
                //是否有标签管理权限
                if (childValue.name == "labelManagemen") {
                  if (this.utils.identity("标签管理")) {
                    childValue.meta.doubleDeck = true;
                    childValue.children.forEach((label) => {
                      // 是否有题库权限
                      if (label.name == "questionBank") {
                        if (this.utils.identity("题库")) {
                          label.meta.teacherShow = true;
                        }
                      }
                      // 是否有标签权限
                      if (label.name == "materialGather_list") {
                        if (this.utils.identity("标签")) {
                          label.meta.teacherShow = true;
                        }
                      }
                    });
                  }
                }
              });
            }
            // 是否有错题本权限
            if (item.name == "wrong-book") {
              if (this.utils.identity("错题本")) {
                item.meta.teacherShow = true;
              }
            }
            if (item.name == "vocationalEducation") {
              if (this.utils.identity("题目管理")) {
                item.meta.teacherShow = true;
                item.children.forEach((childValue) => {
                  // 是否有课程管理权限
                  if (childValue.name == "course") {
                    if (this.utils.identity("课程管理")) {
                      childValue.meta.teacherShow = true;
                    }
                  }

                  //是否有专题管理权限
                  if (childValue.name == "specialTopic") {
                    if (this.utils.identity("专题管理")) {
                      childValue.meta.teacherShow = true;
                    }
                  }
                });
              }
            }
            // 是否是展示首页
            if (item.name == "home") {
              if (this.utils.identity("展示首页")) {
                item.meta.teacherShow = true;
              }
            }
            // 学生查看学情详情
            if (item.name == "chartStudents") {
              if (this.utils.identity("展示学生学情")) {
                item.meta.teacherShow = true;
              }
            }
            // 老师查看学情详情
            if (item.name == "chart-manage") {
              if (this.utils.identity("学情展示分析")) {
                item.meta.teacherShow = true;
              }
            }
          });
        }
      });
    }
    // 监听
    this.$root.Bus.$on("toggle", (value) => {
      this.collapsed = !value;
    });
  },
};
</script>
<style lang="less" scoped>
@import "../style/mouse.less";
.title-span {
  font-size: 16px;
}
.title-menu {
  width: 150px;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 180px;
  height: 100%;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  border: none;
  text-align: left;
}

.el-menu-item-group__title {
  padding: 0px;
}

.el-menu-bg {
  background-color: #fff !important;
}

/deep/.el-menu {
  border: none;
}

.el-menu-item:hover,
.el-submenu .el-submenu__title:hover {
  background-color: #f6faff !important;
}

.el-menu-item.is-active {
  background-color: #f6faff !important;
}

.el-submenu__title i {
  font-weight: bold;
  color: #367ee7;
}
.el-submenu__title {
  font-size: 16px !important;
}
.logobox {
  height: 40px;
  line-height: 40px;
  color: #9d9d9d;
  padding: 20px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  span {
    color: #1f1f1f;
    margin-left: 0.75rem;
    font-weight: bolder;
  }
}

.logoimg {
  height: 24px;
}

.icon {
  margin-right: 1.25rem;
  color: #367ee7 !important;
  font-weight: bolder;
}
.item_p {
  min-width: 0 !important;
}
</style>
